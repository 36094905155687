.hljs-keyword {
  color: #569CD6;
}

.hljs-built_in {
  color: #DCDCAA;
}

.hljs-literal {
  color: #4987c1;
}

.hljs-symbol {
  color: #bc82bc;
}

.hljs-comment {
  color: #6A9955;
}

.hljs-string {
  color: #c4876d;
}

.hljs-number {
  color: #9ec4a4;
}

.hljs-title.function_ {
  color: #DCDCAA;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: 700;
}