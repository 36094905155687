/* nouislider - 11.0.3 - 2018-01-21 14:04:07 */

.noUi-target,
.noUi-target * {
  touch-action: none;
  user-select: none;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
  margin: 30px 10px 10px;

  &[data-has-pips="true"] {
    margin-bottom: 60px;
  }

  &[data-drag-start="true"] {
    .noUi-tooltip {
      opacity: 1;
    }
  }

  &[data-single-handle="true"] {
    margin-top: 10px;

    &[data-handle-on-left] {
      &.noUi-horizontal .noUi-tooltip {
        bottom: -3px;
        right: var(--scrollbar-browser-width);
        white-space: nowrap;
      }
    }

    &[data-handle-on-left="true"] {
      &.noUi-horizontal .noUi-tooltip {
        left: var(--scrollbar-browser-width);
        right: unset;
      }
    }
  }
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

html:not([data-rtl="true"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}

.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  position: absolute;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-horizontal {
  height: 4px;
}

.noUi-horizontal .noUi-handle {
  width: 15px;
  height: 15px;
  left: -7.5px;
  top: -6px;
}

.noUi-vertical {
  width: 4px;
}

.noUi-vertical .noUi-handle {
  width: 15px;
  height: 15px;
  left: -6px;
  top: -7.5px;
}

html:not([data-rtl="true"]) .noUi-horizontal .noUi-handle {
  right: -7.5px;
  left: auto;
}

.noUi-target {
  background: #cccccc;
  clear: both;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: var(--primary-color);
}

.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  border: 1px solid lightgray;
  border-radius: 50%;
  cursor: pointer;
  width: 15px;
  height: 15px;
  box-shadow:
    inset 0 1px 1px #fff,
    0 1px 2px rgba(0, 0, 0, 0.4);
  background: #fff;
  background: linear-gradient(180deg, #fff 0, #f1f1f2);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#f1f1f2", GradientType=0);
}

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}

.card.disabled .slider,
.slider[disabled] {
  .noUi-connect {
    background: #b8b8b8;
  }

  &.noUi-target,
  &.noUi-handle,
  .noUi-handle {
    cursor: not-allowed;
  }
}

.noUi-pips,
.noUi-pips * {
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

.noUi-value {
  color: var(--color-black);
  font-size: 1.2rem;
  margin-top: 8px;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 1rem;
}

.noUi-marker {
  position: absolute;
  background: #ccc;
}

.noUi-marker-sub {
  background: #aaa;
}

.noUi-marker-large {
  background: #aaa;
}

.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  height: 5px;
  width: 1px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  transform: translate(0, -50%, 0);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  background: var(--color-white);
  border-radius: var(--border-radius-base);
  display: block;
  position: absolute;
  text-align: center;
}

.noUi-horizontal .noUi-tooltip {
  bottom: 15px;
  border: 1px solid var(--color-gray);
  font-size: var(--form-group-label-font-size);
  font-weight: bold;
  padding: 0px 3px;
}

.noUi-vertical .noUi-tooltip {
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.slider-primary {

  & .noUi-connect,
  &.noUi-connect {
    background: var(--color-blue);
  }

  & .noUi-handle {
    border-color: var(--color-blue);
  }
}

.slider-info {

  & .noUi-connect,
  &.noUi-connect {
    background: var(--primary-color);
  }

  & .noUi-handle {
    border-color: var(--primary-color);
  }
}

.slider-success {

  & .noUi-connect,
  &.noUi-connect {
    background: var(--color-green);
  }

  & .noUi-handle {
    border-color: var(--color-green);
  }
}

.slider-warning {

  & .noUi-connect,
  &.noUi-connect {
    background: var(--color-orange);
  }

  & .noUi-handle {
    border-color: var(--color-orange);
  }
}

.slider-danger {

  & .noUi-connect,
  &.noUi-connect {
    background: var(--color-red);
  }

  & .noUi-handle {
    border-color: var(--color-red);
  }
}

.event-timeline-fixed {
  pointer-events: none;

  &.noUi-target {
    margin-top: 70px;
  }

  .noUi-tooltip {
    background: transparent;
    border: 0;
    bottom: 36px;
    margin: 0;
    padding: 0;
    transform: translateX(-10px) rotateZ(-45deg);
    white-space: nowrap;

    &::before,
    &::after {
      display: none;
    }
  }
}

@media (max-width: $screen-md) {
  .noUi-target[data-hide-pips-values-sm="true"] .noUi-value-horizontal[data-value] {
    display: none;

    &:nth-child(2),
    &:last-child {
      display: block;
    }
  }
}

@media (max-width: $screen-sm) {
  .noUi-target[data-hide-pips-values-xs="true"] .noUi-value-horizontal[data-value] {
    display: none;

    &:nth-child(2),
    &:last-child {
      display: block;
    }
  }
}