.entity-workflow-card-status {
  margin: 0;
  margin-left: 5px;
}

.workflow-document-modal {
  embed {
    min-height: 80vh;
  }
}

.entity-workflow-buttons {
  .btn i {

    &[class^="icn-"],
    &[class*=" icn-"] {
      max-height: var(--icn-xs);
      max-width: var(--icn-xs);
      min-width: var(--icn-xs);
    }
  }

  @for $i from 1 through 40 {
    >.btn:nth-child(#{$i}) {
      z-index: 40 - $i;
    }
  }
}

@media (max-width: $screen-xs) {
  .entity-workflow-card-status {
    float: right;
  }

  .entity-workflow-buttons {

    .transitions-top,
    .transitions-bottom {
      display: grid;
      grid-gap: calc(var(--btns-margin) * 2);
      grid-template-columns: repeat(2, 1fr);

      .btn-group {
        display: inline-flex;

        >.btn:first-child {
          flex-grow: 1;
        }
      }

      .btn,
      .btn-group {
        float: none !important;
        margin: 0;
        white-space: normal;
      }
    }
  }
}