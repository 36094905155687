.modal-content {
  border-radius: var(--modal-border-radius);
  border: var(--modal-border);
}

.modal-header {
  border-bottom-color: var(--card-border-color);
  border-top-left-radius: var(--modal-border-radius);
  border-top-right-radius: var(--modal-border-radius);
  color: var(--card-header-text-color);
  font-size: var(--font-size-h4);
  line-height: var(--line-height);
  padding: var(--card-header-padding);
  position: relative;
  z-index: 3;

  .close {
    color: var(--card-header-text-color);
  }

  h4 {
    font-weight: var(--card-title-font-weight);
  }
}

.modal-body {
  padding: var(--card-content-padding);
  position: relative;
  z-index: 2;

  legend {
    font-weight: var(--card-title-font-weight);
  }

  .table {
    margin-bottom: 0;
  }
}

.modal-footer {
  padding: var(--card-footer-padding);
  position: relative;
  z-index: 1;
}

.modal-xl {
  width: 80%;
}

.modal-open {
  .modal {
    overflow-y: scroll;
    padding-left: 0 !important;
  }
}

@media (max-width: $screen-xs) {
  .modal-xl {
    width: unset;
  }
}