.carousel {
  border: var(--card-border);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-box-shadow);
  background-color: var(--card-background);
  margin-bottom: var(--card-margin-bottom);
  overflow: hidden;
  position: relative;

  img {
    // max-height and min-height can be changed via the component props
    max-height: 440px;
    min-height: 250px;
    object-fit: cover;
  }
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;

  >.item {
    position: relative;
    display: none;
    transition: 0.6s ease-in-out left;

    >img {
      display: block;
      max-width: 100%;
      height: auto;
      line-height: 1;
    }

    >a {
      >img {
        display: block;
        max-width: 100%;
        height: auto;
        line-height: 1;
      }
    }
  }

  >.active {
    display: block;
    left: 0;
  }

  >.next {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    left: 100%;
  }

  >.prev {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    left: -100%;
  }

  >.next.left {
    left: 0;
  }

  >.prev.right {
    left: 0;
  }

  >.active.left {
    left: -100%;
  }

  >.active.right {
    left: 100%;
  }
}

.carousel-control {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 2rem;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0);
  filter: alpha(opacity=50);
  opacity: 0.5;

  &:hover {
    color: #fff;
    text-decoration: none;
    filter: alpha(opacity=90);
    outline: 0;
    opacity: 0.9;
  }

  &:focus {
    color: #fff;
    text-decoration: none;
    filter: alpha(opacity=90);
    outline: 0;
    opacity: 0.9;
  }

  .icon-prev {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    margin-top: -10px;
    left: 50%;
    margin-left: -10px;
    width: 20px;
    height: 20px;
    font-family: serif;
    line-height: 1;

    &:before {
      content: "\2039";
    }
  }

  .icon-next {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    margin-top: -10px;
    right: 50%;
    margin-right: -10px;
    width: 20px;
    height: 20px;
    font-family: serif;
    line-height: 1;

    &:before {
      content: "\203a";
    }
  }

  .glyphicon-chevron-left {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    margin-top: -10px;
    left: 50%;
    margin-left: -10px;
  }

  .glyphicon-chevron-right {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    margin-top: -10px;
    right: 50%;
    margin-right: -10px;
  }
}

.carousel-control.left {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
  background-repeat: repeat-x;
}

.carousel-control.right {
  right: 0;
  left: auto;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
  background-repeat: repeat-x;
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 0;
  z-index: 15;
  padding: 0;
  list-style: none;
  text-align: center;
  width: 100%;

  li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #fff;
    border-radius: 10px;
  }

  .active {
    width: 12px;
    height: 12px;
    margin: 0;
    background-color: #fff;
  }
}

.carousel-caption {
  position: absolute;
  bottom: 20px;
  left: calc(var(--block-spacing) * 2);
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: var(--color-white);

  .carousel-title {
    font-size: 3.6rem;
    font-weight: bold;
    margin-bottom: 0;
  }

  .carousel-sub-title {
    margin-bottom: var(--block-spacing);
  }

  .btn {
    text-shadow: none;
  }
}

// one day we could add props to the content component carousel
// to control text alignment and display of the after overlay
.content-component {
  .carousel-inner {
    >.item {
      &::after {
        background: rgba(0, 0, 0, 0.25);
        content: "";
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;
      }
    }
  }

  .carousel-indicators {
    left: calc(var(--block-spacing) * 2);
    text-align: initial;
  }
}

@media screen and (min-width: $screen-sm) {
  .carousel-control {
    .glyphicon-chevron-left {
      width: 30px;
      height: 30px;
      margin-top: -10px;
      font-size: 3rem;
      margin-left: -10px;
    }

    .glyphicon-chevron-right {
      width: 30px;
      height: 30px;
      margin-top: -10px;
      font-size: 3rem;
      margin-right: -10px;
    }

    .icon-prev {
      width: 30px;
      height: 30px;
      margin-top: -10px;
      font-size: 3rem;
      margin-left: -10px;
    }

    .icon-next {
      width: 30px;
      height: 30px;
      margin-top: -10px;
      font-size: 3rem;
      margin-right: -10px;
    }
  }

  .carousel-caption {
    padding-bottom: 30px;
  }

  .carousel-indicators {
    bottom: 20px;
  }
}

@media all and (transform-3d) {
  .carousel-inner {
    >.item {
      transition: transform 0.6s ease-in-out;
      backface-visibility: hidden;
      perspective: 1000px;
    }

    >.item.next {
      left: 0;
      transform: translate3d(100%, 0, 0);
    }

    >.item.active.right {
      left: 0;
      transform: translate3d(100%, 0, 0);
    }

    >.item.prev {
      left: 0;
      transform: translate3d(-100%, 0, 0);
    }

    >.item.active.left {
      left: 0;
      transform: translate3d(-100%, 0, 0);
    }

    >.item.next.left {
      left: 0;
      transform: translate3d(0, 0, 0);
    }

    >.item.prev.right {
      left: 0;
      transform: translate3d(0, 0, 0);
    }

    >.item.active {
      left: 0;
      transform: translate3d(0, 0, 0);
    }
  }
}