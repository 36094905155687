@media print {
  html[data-ucsl="true"] {
    height: auto;
    overflow-y: auto;

    body {
      height: auto;
      overflow: auto !important;
      padding-top: 0;
    }
  }

  .container {
    padding: 0;
  }

  .app-controls-wrapper,
  .nav-controls-wrapper,
  .documentation-controls {
    display: none !important;
  }

  .pages-layout {
    margin: 0;
    width: 100%;
  }

  .card {
    border: 0;
  }

  .documentation-page,
  .api-documentation-page {

    pre,
    img {
      border: 1px solid var(--color-gray);
    }

    code {
      font-weight: bold;
      padding: 0;
    }

    pre code {
      font-weight: normal;
    }

    .card .content {
      max-width: unset;
    }

    // this remove the underline of the TOC
    div ul:first-of-type a {
      text-decoration: none;
    }
  }
}