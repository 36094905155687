/*
* https://github.com/YouCanBookMe/react-datetime
*/

.rdt {
  position: relative;

  .rdtPicker {
    transition: all 150ms linear;
    margin-top: -20px;
    visibility: hidden;
    display: block;
    opacity: 0;
  }

  &.rdtOpen {
    .rdtPicker {
      opacity: 1;
      visibility: visible;
      margin-top: 0;
    }
  }
}

.rdtPicker {
  border-radius: var(--border-radius-base);
  display: none;
  position: absolute;
  width: 260px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  border: 1px solid var(--color-gray);
  background-clip: padding-box;
  min-width: 160px;

  &:before {
    border-bottom: 11px solid var(--color-gray);
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    left: 12px;
    top: -11px;
  }

  &:after {
    border-bottom: 11px solid #ffffff;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    left: 12px;
    top: -10px;
  }
}

.rdtOpen .rdtPicker {
  display: block;
  top: 49px;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
  padding: 5px;
  border-radius: 4px;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
  border-color: var(--color-white) !important;
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  padding: 1px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtDay {
  height: 30px;
  line-height: 33px;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: var(--border-radius-base);

  &.rdtToday.rdtActive,
  &.rdtActive,
  &.rdtActive:hover {
    background-color: var(--primary-color) !important;
    color: var(--color-white);
  }
}

.rdtDays {
  tr {
    .dow {
      text-align: center;
      border-bottom: 1px solid var(--color-gray-lighter);
      font-size: 1.2rem;
      text-transform: uppercase;
      color: var(--muted-color);
      font-weight: 400;
      padding-bottom: 5px;
      padding-top: 10px;
    }

    .rdtOld,
    .rdtNew {
      color: var(--color-gray);
    }
  }
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}

.rdtPicker td.rdtToday {
  border: 1px solid var(--color-gray);
  position: relative;

  &.rdtActive {
    border-color: var(--primary-color);
  }
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
}

.rdtPicker th.rdtSwitch {
  width: 50px;
  padding: 5px;
  border-radius: 4px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 2.1rem;
  vertical-align: top;
  border-radius: 50%;
  line-height: 33px;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
  cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters {
  >div {
    float: left;
    width: 40px;
    font-weight: 300;
    margin: 3px;
    border-radius: 50%;
  }

  .rdtCounterSeparator {
    width: 0;
    border: 1px solid transparent;
  }
}

.rdtCounter {
  height: 100px;
  width: 40px;

  .rdtCount {
    padding: 7px;
    height: 40px;
    border: 1px solid transparent;
  }
}

.rdtCounters {
  .rdtCounter:last-child {
    .rdtCount {
      color: var(--color-blue);
      border-radius: 50%;
      border: 1px solid var(--color-blue);
    }
  }
}

.rdtCounterSeparator {
  padding: 7px;
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  line-height: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  color: var(--color-gray-darkest);
  transition: all 60ms ease-in;
  user-select: none;
}

.rdtCounter .rdtBtn:hover {
  background: #eee;
  color: #797979;
}

.rdtCounter .rdtCount {
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtMonths,
.rdtYears {
  padding-bottom: 10px;

  .rdtMonth,
  .rdtYear {
    display: inline-block;
    width: 56px;
    height: 56px;
    line-height: 56px;
    margin: 3px 3px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;

    &.rdtActive {
      background-color: var(--color-blue) !important;
      color: var(--color-white);
    }
  }
}

// react-datetime
.rdtSwitch {
  &:first-letter {
    text-transform: uppercase;
  }
}