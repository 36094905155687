.form-group {
  margin-bottom: var(--form-group-margin-bottom);

  .btn-group {
    min-height: var(--input-min-height);

    &.disabled {
      pointer-events: none;
    }
  }

  .grid-btn-group {
    min-height: unset; // to avoid using :not()
  }
}

.validation-warning {
  border-bottom: 3px solid transparent;
  color: var(--warning-color);
  font-size: 1.2rem;
}

.form-group-error-focus .validation-warning {
  border-bottom-color: var(--warning-color);
}

.validation-error {
  border-bottom: 3px solid transparent;
  color: var(--danger-color);
  font-size: 1.2rem;

  &.section-error {
    margin: calc(-1 * var(--block-spacing) / 2) calc(var(--block-spacing) / 2) 0;
  }
}

.form-group-error-focus .validation-error {
  border-bottom-color: var(--danger-color);
}

.card-warning {
  border-bottom: 3px solid transparent;
  color: var(--warning-color);
  font-size: 1.2rem;
}

.card-error-focus .card-warning {
  border-bottom-color: var(--warning-color);
}

.card-error {
  border-bottom: 3px solid transparent;
  color: var(--danger-color);
  font-size: 1.2rem;
}

.card-error-focus .card-error {
  border-bottom-color: var(--danger-color);
}

.form-input-helper {
  font-size: 1.2rem;
  margin-top: 2px;
}

.form-input-min-max {
  font-size: 1.2rem;
  margin-top: 2px;
}

.field-debug {
  background-color: var(--info-color);
  border-radius: 10px;
  color: var(--color-white);
  cursor: help;
  font-size: 1.2rem;
  font-weight: 700;
  height: 18px;
  line-height: 18px;
  min-width: 18px;
  padding: 0 5px;
  position: absolute;
  right: 0;
  text-align: center;
  top: -9px;
  z-index: 100;

  &.table-header-debug {
    right: 2px;
    top: 0;
  }

  &.field-debug-btn,
  &.field-debug-link {
    cursor: pointer;

    &:hover {
      background-color: var(--color-states-blue);
    }
  }

  &.field-debug-btn {
    right: -7px;
    top: -7px;
  }

  &.field-debug-help {
    background: var(--color-gray);
  }
}

.control-label .field-debug {
  top: 12px;
}

.form-control-static {
  background: var(--muted-background-color);
  border-bottom: 1px dotted var(--input-border-color);
  border-color: var(--muted-background-color);
  border-radius: var(--border-radius-base);
}

.form-control {
  box-shadow: none;
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius-base);
  color: var(--text-color);
  padding: var(--input-v-padding) var(--input-h-padding);
  height: var(--input-min-height);
  transition: border 0.3s linear;

  &:focus {
    border: 1px solid var(--input-border-focus-color);
    box-shadow: none;
  }

  &[type="color"] {
    padding: 0;
  }
}

textarea.form-control {
  height: auto;
  min-height: var(--textarea-min-height);
  resize: vertical;

  &[disabled] {
    overflow-y: auto;
  }
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control,
.input-group[data-disabled="true"] .input-group-addon {
  background-color: var(--muted-background-color);
  border-color: var(--muted-background-color);
  color: var(--color-black-lighter);
  resize: none;
}

.input-group[data-disabled="true"] .input-group-addon {
  padding-left: var(--input-addon-h-padding);
  padding-right: 0;
}

.control-label {
  color: var(--text-color);
  font-size: var(--form-group-label-font-size);
  margin-bottom: 2px;
  transition: color 0.3s linear;

  &:empty {
    display: none;
  }
}

input {
  filter: none;

  &::placeholder {
    color: var(--input-placeholder-color) !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }

  &[type="file"] {
    display: none;
  }

  &[type="range"] {
    appearance: none;
    margin: 18px 0;
    width: 100%;

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 8.4px;
      cursor: pointer;
      background: var(--primary-color);
      border-radius: 1.3px;
    }

    &::-webkit-slider-thumb {
      appearance: none;
      box-shadow:
        1px 1px 1px var(--primary-color),
        0px 0px 1px var(--primary-color);
      border: 1px solid var(--primary-color);
      height: 30px;
      width: 15px;
      border-radius: 3px;
      background: #ffffff;
      cursor: pointer;
      margin-top: -14px;
    }

    &:focus::-webkit-slider-runnable-track {
      background: var(--primary-color);
    }

    &::-moz-range-track {
      width: 100%;
      height: 8.4px;
      cursor: pointer;
      background: var(--primary-color);
      border-radius: 1.3px;
    }

    &::-moz-range-thumb {
      height: 36px;
      width: 16px;
      border-radius: 3px;
      background: #ffffff;
      cursor: pointer;
    }

    &::-ms-track {
      width: 100%;
      height: 8.4px;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      border-width: 16px 0;
      color: transparent;
    }

    &::-ms-fill-lower {
      background: var(--primary-color);
      border-radius: 2.6px;
    }

    &::-ms-fill-upper {
      background: var(--primary-color);
      border-radius: 2.6px;
    }

    &::-ms-thumb {
      height: 36px;
      width: 16px;
      border-radius: 3px;
      background: #ffffff;
      cursor: pointer;
    }

    &:focus::-ms-fill-lower {
      background: var(--primary-color);
    }

    &:focus::-ms-fill-upper {
      background: var(--primary-color);
    }
  }
}

.select-btn {
  color: var(--color-gray-darker);

  &:hover {
    color: var(--color-gray-darkest);
  }
}

.input-group-addon {
  background-color: var(--color-white);
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius-base);
  transition: border 0.3s linear;
}

.input-group .form-control:first-child:not(:last-child),
.input-group-addon:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-right: 0 none var(--color-gray-lighter); // set the color to avoid flashes with the default black color when transitioning

  &.form-input-addon {
    padding-right: 0;
  }
}

.input-group .form-control:last-child:not(:first-child),
.input-group-addon:last-child,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
  border-left: 0 none var(--color-gray-lighter); // set the color to avoid flashes with the default black color when transitioning

  &.form-input-addon {
    padding-left: 0;
  }
}

.input-group:not([data-disabled="true"]) .input-group-addon + .form-control[disabled] {
  border-left: 1px solid var(--input-border-color);
}

.input-group {
  transition: border 0.3s linear;
  width: 100%;

  &.input-group-addon + .select-group .select__control {
    border-radius: 0 var(--border-radius-base) var(--border-radius-base) 0;
  }

  &.select-group:first-child:not(:last-child) .select__control {
    border-radius: var(--border-radius-base) 0 0 var(--border-radius-base);
  }

  &:focus-within .input-group-addon {
    border-color: var(--input-border-focus-color);

    .control-label {
      color: var(--input-label-focus-color);
    }

    .input-group-addon,
    .form-control {
      border-color: var(--input-border-focus-color);
    }

    .input-group-addon {
      color: var(--input-label-focus-color);
      transition: all 0.3s linear;
    }
  }
}

.form-input-inline {
  align-items: center;
  cursor: text;
  display: flex;
  height: 28px;
  position: relative;

  &.form-input-inline-read-only {
    &.form-input-in-array {
      display: inline-flex;
      height: auto;
    }

    i {
      margin: 0 4px;
    }
  }

  input {
    padding-left: 4px;
    height: 28px;
  }

  .form-input-inline-controls {
    position: absolute;
    right: 4px;

    i {
      cursor: pointer;
    }
  }
}

.form-input-subtext {
  color: var(--color-gray-darkest);
  font-size: 1.2rem;
}

.form-input-file-drop-area {
  overflow: hidden;
  position: relative;

  .control-label {
    display: block;
  }
}

[data-show-drop-area-hint="true"]::before {
  background: rgba(0, 0, 0, 0.55);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.form-input-file {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .form-input-filename {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.color-input-no-color {
  background-image: linear-gradient(45deg, #f0f0f0 25%, #ffffff 25%, #ffffff 50%, #f0f0f0 50%, #f0f0f0 75%, #ffffff 75%, #ffffff 100%);
  background-size: 28.28px 28.28px;

  &.in-array {
    height: 24px;
  }
}

input[type="button"] {
  appearance: button;
  cursor: pointer;
}

input[disabled] {
  cursor: default;
}

input[type="color"] {
  appearance: none;
  border: none;

  &.in-array {
    height: 24px;
  }

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  &::-webkit-color-swatch {
    border: none;
    border-top-left-radius: var(--border-radius-base);
    border-bottom-left-radius: var(--border-radius-base);

    &:hover {
      cursor: pointer;
    }
  }

  &[disabled]::-webkit-color-swatch {
    border-radius: var(--border-radius-base);
  }

  &:focus {
    border: none;
  }
}

.form-input-datetime {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    width: calc(50% - (var(--block-spacing) / 2));
  }

  .form-group {
    margin-bottom: 0;
  }
}

.form-input-markdown {
  background-color: var(--color-gray-lightest);
  border-radius: var(--border-radius-base);
  margin-bottom: var(--form-group-margin-bottom);
}
