.container-note-other-users {
  display: flex;
}

.container-note-user {
  display: flex;
  justify-content: flex-end;
}

.note-user-circle {
  $note-user-circle-size: 36px;

  align-items: center;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  height: $note-user-circle-size;
  justify-content: center;
  min-width: $note-user-circle-size;
  width: $note-user-circle-size;
}

.note-card-user {
  background-color: var(--color-gray-lightest);
  border-radius: var(--border-radius-base);
  max-width: 800px;
  width: 100%;
}

.note-update {
  width: 100%;

  textarea.form-control,
  .form-group {
    background-color: var(--color-white);
  }
}

.note-metadata {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;

  span {
    &:first-child {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
    }

    &:last-child {
      min-width: 60px;
    }
  }
}

.note-text {
  border: 0;
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  word-break: break-word;
  text-wrap: wrap;

  p:last-child {
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
  }
}

.note-update-type-buttons {
  justify-content: space-between;
}

.notes-list {
  max-height: 65vh;
  overflow-y: auto;
}

@media (max-width: $screen-md) {

  .note-card-user,
  .note-update {
    min-width: unset;
    max-width: unset;
    width: 100%;
  }
}