// height and width must be either 355px or 400px
// use whatever gives the best proportions
[class^="icn-"],
[class*=" icn-"] {
  display: inline-block;
  height: var(--icn-md);
  line-height: 1;
  min-width: var(--icn-md);
  transition: transform 0.3s;
  vertical-align: middle;
  width: var(--icn-md);

  &.icn-xxxs {
    height: var(--icn-xxxs);
    line-height: 0;
    min-width: var(--icn-xxxs);
    width: var(--icn-xxxs);
  }

  &.icn-xxs {
    height: var(--icn-xxs);
    min-width: var(--icn-xxs);
    width: var(--icn-xxs);
  }

  &.icn-xs {
    height: var(--icn-xs);
    min-width: var(--icn-xs);
    width: var(--icn-xs);
  }

  &.icn-sm {
    height: var(--icn-sm);
    min-width: var(--icn-sm);
    width: var(--icn-sm);
  }

  &.icn-lg {
    height: var(--icn-lg);
    min-width: var(--icn-lg);
    width: var(--icn-lg);
  }

  &.icn-xl {
    height: var(--icn-xl);
    min-width: var(--icn-xl);
    width: var(--icn-xl);
  }

  &.icn-xxl {
    height: var(--icn-xxl);
    min-width: var(--icn-xxl);
    width: var(--icn-xxl);
  }

  &::before {
    background-color: currentColor;
    content: "";
    display: inline-block;
    height: 100%;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    width: 100%;
  }

  &.icn-flag::before {
    background-color: transparent;
  }
}

.icn-spin {
  animation: icn-spin 2s infinite linear; // from index.html
}

.icn-rotate-180 {
  transform: rotateZ(180deg);
}

@each $icn in $icns {
  .icn-#{$icn}:not(.icn-flag)::before {
    background-color: currentColor;
    content: "";
    mask-image: var(--icn-#{$icn}-mask-image);
  }

  .icn-#{$icn}.icn-flag::before {
    background-image: var(--icn-#{$icn}-mask-image);
  }
}
