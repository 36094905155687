.checkbox,
.radio {
  margin: 0;
  min-height: var(--input-min-height);
  padding-top: 7px;

  input:focus+label::before {
    content: "";
    display: block;
    // true black here for highest accessibility contrast
    border: 2px solid black;
    position: absolute;
    left: -3px;
    top: 0;
  }

  label {
    cursor: pointer;
    display: inline-block;
    line-height: var(--icn-sm);
    margin-bottom: 0;
    padding-left: var(--icn-sm);
    position: relative;
  }
}

.checkbox-align .checkbox {
  min-height: unset;
  padding-top: 6px;
}

.checkbox {
  input:focus+label::before {
    height: var(--icn-sm);
    width: var(--icn-sm);
    border-radius: 2px;
  }

  label {
    &::after {
      background-color: currentColor;
      color: var(--color-gray-darkest);
      content: "";
      cursor: pointer;
      display: inline-block;
      height: var(--icn-sm);
      left: -3px;
      line-height: var(--icn-sm);
      mask-image: var(--icn-checkbox-mask-image);
      position: absolute;
      top: 0px;
      transition: color 0.2s linear;
      width: var(--icn-sm);
      text-align: center;
    }
  }
}

.checkbox input[type="checkbox"],
.radio input[type="radio"] {
  margin-left: 0;
  opacity: 0;
}

.checkbox input[type="checkbox"]:checked+label {
  &::after {
    background-color: currentColor;
    color: var(--primary-color);
    content: "";
    mask-image: var(--icn-checkbox-checked-mask-image);
  }
}

.checkbox input[type="checkbox"]:disabled+label,
.radio input[type="radio"]:disabled+label {
  color: var(--text-color);
}

.checkbox input[type="checkbox"]:disabled:checked+label::after {
  color: var(--color-gray-darkest);
}

.checkbox input[type="checkbox"]:disabled+label {

  &::after,
  &::before {
    cursor: not-allowed;
  }
}

.checkbox input[type="checkbox"]:disabled+label,
.radio input[type="radio"]:disabled+label {
  cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  padding-left: 0;
}

.checkbox-primary input[type="checkbox"]:checked+label::before {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.checkbox-danger input[type="checkbox"]:checked+label::before {
  background-color: var(--danger-color);
  border-color: var(--danger-color);
}

.checkbox-info input[type="checkbox"]:checked+label::before {
  background-color: var(--info-color);
  border-color: var(--info-color);
}

.checkbox-warning input[type="checkbox"]:checked+label::before {
  background-color: var(--warning-color);
  border-color: var(--warning-color);
}

.checkbox-success input[type="checkbox"]:checked+label::before {
  background-color: var(--success-color);
  border-color: var(--success-color);
}

.checkbox-primary input[type="checkbox"]:checked+label::after,
.checkbox-danger input[type="checkbox"]:checked+label::after,
.checkbox-info input[type="checkbox"]:checked+label::after,
.checkbox-warning input[type="checkbox"]:checked+label::after,
.checkbox-success input[type="checkbox"]:checked+label::after {
  color: var(--color-white);
}

.radio-group[data-inline="true"] {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  .radio {
    margin-right: var(--block-spacing);
    margin-top: 0;
  }
}

.radio {
  input:focus+label::before {
    height: var(--icn-sm);
    width: var(--icn-sm);
    border-radius: 50%;
  }

  label {
    &::after {
      background-color: currentColor;
      color: var(--color-gray-darker);
      content: "";
      display: inline-block;
      left: -3px;
      line-height: var(--icn-sm);
      height: var(--icn-sm);
      mask-image: var(--icn-circle-mask-image);
      position: absolute;
      top: 0;
      transition: color 0.2s linear;
      width: var(--icn-sm);
    }
  }
}

.radio input[type="radio"] {
  &:checked {
    &+label::after {
      background-color: currentColor;
      color: var(--color-gray-darker);
      content: "";
      mask-image: var(--icn-circle-dot-mask-image);
    }

    &:not(:disabled):hover+label::after,
    &+label::after {
      color: var(--color-blue);
    }
  }

  &:disabled+label {
    color: var(--color-gray-darkest);

    &::after,
    &::before {
      color: var(--color-gray-darkest);
    }
  }
}

.radio.radio-inline {
  margin-top: 0;
}

.checkbox[data-disabled="true"] {
  background: var(--muted-background-color);
  border-radius: var(--border-radius-base);
  color: var(--input-placeholder-color);
  margin: 0;
  min-height: 34px;
  padding: 7px 4px 0;
}

.table td> {

  .checkbox,
  .radio {
    display: block;
    height: 20px;
    position: relative;
    margin: 0;
    min-height: unset;
    padding: 0;
  }

  .checkbox[data-disabled="true"] {
    background: transparent;
  }
}