// the select-group class and the select__ prefix
// are set on the react-select components
.select-group {
  .select__control {
    border: 1px solid var(--input-border-color);
    border-radius: var(--border-radius-base);
    box-shadow: none;
    min-height: var(--input-min-height);
    padding: 2px;
    transition: border 0.3s linear;

    // this one should not be necessary
    // however the framework that provides the select inputs
    // add a custom style for this situation that is not obvious to remove with its options
    &:hover {
      border-color: var(--input-border-color);
    }

    &:focus,
    &:focus-within {
      border-color: var(--input-border-focus-color);
    }

    &.select__control--is-disabled {
      background-color: var(--muted-background-color);
      border-color: var(--muted-background-color);
    }
  }

  .select__control {
    cursor: text;
  }

  .select__value-container {
    font-size: var(--font-size-base);
    padding: 0;
  }

  .select__placeholder {
    color: var(--input-placeholder-color);
  }

  .select__menu {
    border-radius: var(--border-radius-base);
    margin-top: 0;
    padding: 0;
    z-index: 10000;
    width: auto;
    min-width: 100%;
  }

  .select__menu-list {
    font-size: var(--font-size-base);
    padding: 0;

    .select__option {
      display: flex;
      justify-content: space-between;

      &.select__option--is-focused {
        background-color: var(--info-background-color);
        color: var(--text-color);
        outline: var(--accessibility-outline);
        outline-offset: var(--accessibility-outline-offset-inset);
      }

      &.keyboard-select-negative-value .icn-minus {
        outline: var(--accessibility-outline);
        outline-offset: var(--accessibility-outline-offset);
      }
    }
  }

  .select__single-value {
    padding-left: 5px;
  }

  .select__multi-value {
    background-color: var(--primary-color);
    color: var(--color-white);
    padding: 0;
    margin: 3px;

    &.select__multi-value--is-disabled {
      background-color: darkgray;
    }
  }

  .select__multi-value__label {
    color: var(--color-white);
    margin: 0;
    padding: 3px;
  }

  .select__multi-value__remove:hover {
    background-color: var(--primary-states-color);
    color: var(--color-white);
    cursor: pointer;
  }

  .select__clear-indicator {
    padding: 0;
  }

  .select__dropdown-indicator {
    padding: 4px;
  }
}