.buttons-with-no-margin {

  .btn-group .btn,
  .btn {
    margin: 0px;
  }
}

.btn-group .btn {
  margin: 0;

  +.btn {
    margin-left: -1px;
  }
}

.btn {
  --btn-color: var(--color-black-lighter);
  --btn-states-color: var(--color-black);

  border-width: 1px;
  background-color: transparent;
  border-radius: var(--btn-border-radius);
  margin: var(--btns-margin);
  margin-left: 0;
  margin-right: calc(2 * var(--btns-margin));
  padding: var(--btn-lg-padding);
  position: relative;

  &:active,
  &.active,
  .open>&.dropdown-toggle {
    box-shadow: none;
  }

  &.btn-icon {
    padding: 8px;
  }

  &[disabled] {
    border-color: var(--muted-border-color);
    color: var(--input-placeholder-color);
    pointer-events: none;

    &:hover {
      border-color: var(--muted-border-color);
    }

    &.btn-fill {
      background: var(--muted-background-color);
      color: var(--input-placeholder-color);

      &.selected {
        color: var(--text-color);
      }
    }
  }

  &.pull-right {
    margin-left: calc(2 * var(--btns-margin));
    margin-right: 0;
  }

  .btn-status-indicator {
    --btn-status-indicator-size: 8px;
    border-radius: 50%;
    position: absolute;
    height: var(--btn-status-indicator-size);
    top: 1px;
    right: 1px;
    width: var(--btn-status-indicator-size);
  }
}

.btn-primary {
  --btn-color: var(--primary-color);
  --btn-states-color: var(--primary-states-color);
}

.btn-success {
  --btn-color: var(--success-color);
  --btn-states-color: var(--success-states-color);
}

.btn-info {
  --btn-color: var(--info-color);
  --btn-states-color: var(--info-states-color);
}

.btn-warning {
  --btn-color: var(--warning-color);
  --btn-states-color: var(--warning-states-color);
}

.btn-danger {
  --btn-color: var(--danger-color);
  --btn-states-color: var(--danger-states-color);
}

.btn-green {
  --btn-color: var(--btn-green);
  --btn-states-color: var(--btn-green-states-color);
}

.btn-yellow {
  --btn-color: var(--btn-yellow);
  --btn-states-color: var(--btn-yellow-states-color);
}

.btn-orange {
  --btn-color: var(--btn-orange);
  --btn-states-color: var(--btn-orange-states-color);
}

.btn-red {
  --btn-color: var(--btn-red);
  --btn-states-color: var(--btn-red-states-color);
}

.btn-purple {
  --btn-color: var(--btn-purple);
  --btn-states-color: var(--btn-purple-states-color);
}

.btn-blue {
  --btn-color: var(--btn-blue);
  --btn-states-color: var(--btn-blue-states-color);
}

.btn-gray {
  --btn-color: var(--btn-gray);
  --btn-states-color: var(--btn-gray-states-color);
}

.btn-neutral {
  --btn-color: var(--color-white);
  --btn-states-color: var(--color-white);
}

.btn,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger,
.btn-green,
.btn-yellow,
.btn-orange,
.btn-red,
.btn-purple,
.btn-blue,
.btn-gray,
.btn-neutral {
  border-color: var(--btn-color);
  color: var(--btn-color);

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &:active:hover,
  &.active:focus,
  &.active:hover,
  .open>&.dropdown-toggle {
    background-color: transparent;
    color: var(--btn-states-color);
    border-color: var(--btn-states-color);
  }

  &.disabled,
  &:disabled,
  &[disabled],
  fieldset[disabled] & {

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: transparent;
      border-color: var(--btn-color);
    }
  }

  &.split-button,
  &.btn-fill {
    color: var(--color-white);
    background-color: var(--btn-color);

    &:hover,
    &:focus,
    &:active,
    &.active,
    .open>&.dropdown-toggle {
      background-color: var(--btn-states-color);
      color: var(--color-white);
    }

    &.disabled {
      background-color: transparent;
      border-color: var(--btn-color);
    }

    .caret {
      border-top-color: var(--color-white);
    }
  }

  &:focus .caret {
    transform: rotateZ(0);
  }
}

.btn-gray,
.btn-yellow {

  &.split-button,
  &.btn-fill {
    color: var(--text-color);

    &:hover,
    &:focus,
    &:active,
    &.active,
    .open>&.dropdown-toggle {
      color: var(--text-color);
    }
  }
}

.btn-neutral {

  &:active,
  &.active,
  .open>&.dropdown-toggle {
    background-color: var(--color-white);
    color: var(--color-black-lighter);
  }

  &.btn-fill,
  &.btn-fill:hover,
  &.btn-fill:focus {
    color: var(--color-black-lighter);
  }

  &.btn-simple:active,
  &.btn-simple.active {
    background-color: transparent;
  }
}

.btn-hover-display {
  opacity: 0;
  transition: all 0.3s linear;

  &:hover {
    opacity: 1;
  }
}

.btn-round {
  border-width: 1px;
  border-radius: var(--btn-round-border-radius) !important;
}

.btn-simple {
  border: none;
}

.btn-lg {
  padding: var(--btn-lg-padding);
  font-weight: normal;
}

.btn-sm {
  min-height: 26px;
  min-width: 26px;
  padding: var(--btn-sm-padding);
}

.btn-xs {
  min-height: var(--wcag-aa-min-size);
  min-width: var(--wcag-aa-min-size);
  padding: var(--btn-xs-padding);
}

.btn-wd {
  min-width: 100px;
}

.btn-group {
  margin: var(--btns-margin) 0;
  margin-right: calc(2 * var(--btns-margin));

  &.disabled {
    pointer-events: none;

    .btn {
      border-color: var(--muted-border-color);
      color: var(--input-placeholder-color);
    }
  }

  &.dropdown {
    margin-left: 0;
  }

  // so that hovering the btn-group affects the inner buttons
  &:hover>.btn {
    &.btn-fill {
      background-color: var(--btn-states-color);
    }
  }

  >.btn:focus:first-child+.btn .caret {
    transform: rotateZ(0);
  }

  &.select {
    width: 100%;

    .btn {
      text-align: left;
    }

    .caret {
      position: absolute;
      top: 50%;
      margin-top: -1px;
      right: 8px;
    }
  }

  &.pull-right {
    margin-left: calc(2 * var(--btns-margin));
    margin-right: 0;
  }

  >.btn {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .btn+.btn:hover {
    border-left-color: transparent;
  }
}

.btn-search {
  font-size: 3rem;
  padding: 0;
  vertical-align: text-top;
}

.btn-download {
  font-size: 2.5rem;
  padding: 0;
  vertical-align: text-top;
}

.grid-btn-group {
  border-left: 1px solid;
  border-top: 1px solid;
  border-color: var(--primary-color);
  margin-left: 1px;
  margin-top: 1px;

  &.grid-btn-group-muted,
  &.disabled {
    border-color: var(--muted-border-color);
    pointer-events: none;

    button {
      background-color: var(--muted-background-color);
      border-color: var(--muted-border-color);
      color: var(--muted-color);
      pointer-events: none;

      &.btn-fill:hover,
      &:hover {
        border-color: var(--muted-border-color);
      }
    }
  }

  &.btn-group .btn+.btn {
    margin-left: 0;
  }

  button {
    border: 0;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: var(--primary-color);
    line-height: 1.2;
    margin: 0;
    padding: 4px;
  }
}

// This rule counters CustomButton center alignment
.btn-group.open>.dropdown-menu .btn {
  justify-content: left;

  &:not(.btn-fill):hover {
    background-color: var(--muted-background-color);
  }
}

@media (max-width: $screen-xs-sm) {
  .btn-duration {
    width: 50% !important;
    border-radius: 0 !important;
  }
}