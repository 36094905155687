* {
  box-sizing: border-box;

  &:before {
    box-sizing: border-box;
  }

  &:after {
    box-sizing: border-box;
  }

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html {
  font-family: Arial, sans-serif;
  font-size: 10px; // rem unit reference
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &[data-ucsl="true"],
  &[data-in-iframe="true"] {
    height: 100%;
    overflow-y: hidden;

    body {
      height: 100%;
      // to avoid flickering back and forth between pages with or without scroll
      overflow: hidden scroll;
      padding-right: 0;
    }
  }

  &[data-in-scripts-page="true"],
  &.nav-open {
    body {
      overflow-y: hidden;
    }
  }

  &[data-main-view-has-display-width="true"] #main-view {
    margin: 0 auto;
    max-width: var(--main-view-display-width);
  }
}

@media (max-width: $screen-sm) {
  html {
    &[data-in-iframe="true"] {
      overflow-x: hidden;

      body {
        width: calc(100% + var(--scrollbar-browser-width));
      }
    }

    &[data-in-scripts-page="true"] {
      body {
        overflow-y: auto;
      }
    }
  }
}

@media (min-width: $screen-sm-1) {
  html[data-ucsl="true"] {
    body {
      padding-top: var(--app-controls-height);
    }

    &[data-no-decoration="true"] {
      body {
        height: 100%;
        margin-top: 0;
        padding-top: 0;
      }
    }

    .app-controls-wrapper {
      position: fixed;
      right: 0;
      top: 0;
      // right: 0; // don't set this property so that rtl direction is responsive
      width: calc(100% - var(--nav-controls-width));
      z-index: 10001; // must go over the side view
    }
  }
}

body {
  background: var(--body-background);
  color: var(--text-color);
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  line-height: var(--line-height);
  margin: 0;
  overflow-x: hidden;
}

.pull-to-refresh-wrapper {
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  position: fixed;
  transform: translateY(30px);
  transition: transform 0.55s ease-in-out;
  width: 100%;
  z-index: 99999999;

  .pull-to-refresh {
    align-items: center;
    background: rgba(189, 189, 189, 0.55);
    border-radius: 14px;
    display: flex;
    justify-content: center;
    padding: 3px 8px;
    font-size: 1.2rem;
  }

  &.visible {
    transform: translateY(-20px);
  }
}

hr {
  border-top-color: var(--card-border-color);
  margin: 5px 0;
}

i {
  min-width: 18px;
  text-align: center;
}

legend {
  border-top: 1px solid var(--card-border-color);
  border-bottom: none;
  color: var(--card-header-text-color);
  font-size: var(--font-size-h4);
  font-weight: var(--card-title-font-weight);
  padding-top: var(--block-spacing);
  margin-bottom: var(--block-spacing);

  &.content-legend {
    line-height: var(--line-height);
    padding-top: 0;
  }
}

a {
  &.dropdown-toggle[aria-expanded="true"] .caret {
    transform: rotate(0deg);
  }
}

.caret {
  transition: all 0.15s ease-in;
  transform: rotate(-90deg);
}

.divider,
.divider-light {
  background-color: var(--card-border-color);
  clear: both;
  height: 1px;
  overflow: hidden;
  width: 100%;
}

.text-divider {
  text-align: center;

  .text-divider-line {
    display: inline-block;
    width: 100%;
    height: 1px;
    background: var(--card-border-color);
    vertical-align: middle;
  }

  .text-divider-text {
    position: absolute;
    left: 0;
    width: 100%;

    span {
      color: var(--text-color);
      background: var(--card-background);
      padding: 0 20px;
    }
  }
}

.text-spinner {
  position: relative;
  width: 60px;
  height: 60px;
  margin: 0 auto;

  .text-spinner-spinner {
    margin-bottom: 19.5px;
    border: 4px solid hsl(0, 0%, 88%);
    border-left-color: hsl(0, 0%, 20%);
    animation: loaderSpin 1.2s linear infinite;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: 52px;
    height: 52px;
    box-sizing: content-box;
  }

  .text-spinner-text {
    text-align: center;
    display: inline-block;
    color: rgba(0, 0, 0, 0.6);
    line-height: 16px;
    font-size: var(--font-size-base);
    font-weight: 500;
    letter-spacing: 0.25px;
    position: absolute;
    left: 0;
    right: 0;
    top: 21px;
  }
}

@keyframes loaderSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(1turn);
  }
}

@mixin z-index-stacks {
  position: relative;

  @for $i from 1 through 60 {

    &:nth-child(#{$i}),
    >[class*="col-"]:nth-child(#{$i}) {
      z-index: 60 - $i;
    }
  }
}

.container {
  margin: 0 auto;
  padding: var(--container-padding);
  width: 100%;

  .actual-tenant-logo img {
    max-height: 100px;
    max-width: 50%;
  }

  &[data-no-padding="true"] {
    padding: 0;

    >.row {
      margin: 0;

      >div:first-child {
        width: 100%;
      }
    }

    .card,
    .carousel {
      border-radius: 0px;
      margin-bottom: 0px;
    }

    .card-html .content {
      padding: 0;
    }

    .content-component {
      padding: 0;
    }
  }

  /*
   * Global animation to smoothen the rendering of the cards
   * This is needed in order to stack properly the card class
   * as it has an animation that creates a new stacking context
   * and breaks the dropdowns z-index.
   */
  tr,
  .row {
    @include z-index-stacks;
  }

  .entity-extension-section,
  .entity-subextension-section {
    @include z-index-stacks;
  }
}

.modal-content .row {
  @include z-index-stacks;
}

.clear-z-index {
  tr {
    z-index: unset !important;
  }
}

.pseudo-select-control {
  .search-input-icon {
    align-items: center;
    display: flex;
    height: calc(var(--input-min-height) - 2px);
    justify-content: center;
    left: calc(var(--input-h-padding) / 2);

    +.form-control {
      padding-left: calc(var(--icn-md) + var(--input-h-padding) / 2 + 2px);
    }
  }

  .form-control:focus+.pseudo-select-options {
    border-color: var(--input-border-focus-color);
  }

  .pseudo-select-options {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-lighter);
    border-bottom-left-radius: var(--border-radius-base);
    border-bottom-right-radius: var(--border-radius-base);
    left: 0;
    max-height: 300px;
    overflow-y: auto;
    position: absolute;
    top: var(--input-min-height);
    transition: border 0.3s linear;
    width: 100%;

    i {
      min-width: 20px;
    }

    .pseudo-select-option {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      line-height: 1;
      padding: var(--input-v-padding) var(--input-h-padding);

      &[data-has-icon="true"] {
        padding-left: calc(var(--input-h-padding) / 2);
      }

      &.selected-option,
      &:hover {
        background-color: var(--info-background-color);
        outline: var(--accessibility-outline);
        outline-offset: var(--accessibility-outline-offset-inset);
      }
    }
  }
}

// this class mimics the appearance of the input add-ons of react-select/async
.pseudo-select-addons {
  cursor: pointer;
  display: flex;
  height: calc(var(--input-min-height) - 2px);
  padding: 0 4px;
  position: absolute;
  right: 0;
  top: 1px;
  z-index: 10;
}

.nav-tabs>li.active> {

  a,
  a:focus,
  a:hover {
    border-bottom-color: transparent;
    background: var(--color-gray-lightest);
    max-height: 42px; // this is to avoid an expand flicker
  }
}

.nav-tab-wrapper {
  display: block;
  float: left;
  position: relative;

  >.nav-tab {
    border: 1px solid transparent;
    border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
    display: block;
    margin-right: 2px;
    padding: calc(var(--block-spacing) / 2) var(--block-spacing);
    position: relative;

    &:hover {
      cursor: pointer;
      background: var(--color-gray-lighter);
      border: 1px solid var(--card-border-color);
      border-bottom-color: transparent;
    }
  }

  &[data-selected-tab="true"] {
    >.nav-tab {
      cursor: default;
      background: var(--color-gray-lightest);
      border: 1px solid var(--card-border-color);
      border-bottom-color: transparent;
    }
  }
}

.ace_scrollbar,
// ace editor scrollbar
.theme-scrollbar {
  /* Firefox */
  scrollbar-width: thin;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    height: var(--scrollbar-theme-size);
    width: var(--scrollbar-theme-size);
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-theme-track-background);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-theme-thumb-background);
    border: var(--scrollbar-theme-thumb-border);
    border-radius: var(--scrollbar-theme-thumb-border-radius);

    &:hover {
      background-color: var(--scrollbar-theme-thumb-hover-background);
    }
  }
}

.tab-title {
  a {
    font-weight: bold;
    color: var(--card-title-font-weight) !important;
  }
}

.person-registration-component {
  .select__option {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (max-width: $screen-xs) {

  // pages are styled differently and mostly independently from the rest of the app
  // so we must exclude them from this adjustement
  .container:not(.page) {
    padding-left: 0;
    padding-right: 0;
  }

  .pull-to-refresh-wrapper {
    bottom: unset;
    top: 0;
    transform: translateY(-30px);

    &.visible {
      transform: translateY(20px);
    }
  }
}

.side-view {
  --controls-height: 30px;

  background: var(--body-background);
  border-left: 1.5px solid var(--color-gray-lighter);
  height: calc(100vh - var(--app-controls-height));
  max-width: 100vw;
  min-width: var(--container-padding);
  position: fixed;
  right: 0;
  top: var(--app-controls-height);
  width: 75%; // this is just the initial width, users can resize it afterwards
  z-index: 10000; // must go over the workflow card when it is in sticky position

  &[data-mode="split"] {
    width: 50%;
  }

  .side-view-gutter-wrapper {
    background: var(--body-background);
    box-shadow: -4px 0px 3px -3px var(--color-gray);
    cursor: ew-resize;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: var(--container-padding);
  }

  .side-view-gutter {
    background: var(--body-background);
    border: 1px solid var(--color-gray);
    border-radius: var(--border-radius-base);
    left: calc(-1 * var(--container-padding) - 0.5px);
    position: absolute;
    top: 40%;
    user-select: none;
    width: calc(2 * var(--container-padding));
  }

  .side-view-iframe {
    border: 0;
    height: 100%;
    width: 100%;
  }
}

.form-bullet-summary {
  ul {
    cursor: pointer;
    margin-bottom: 5px;
    padding-left: 20px;
  }

  li:hover span {
    text-decoration: underline;
  }
}