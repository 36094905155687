.form-input-rich-text {
  button {
    background-color: transparent;
    border: 0;
  }

  // quill.js base css and modified
  // taken from https://cdnjs.cloudflare.com/ajax/libs/quill/2.0.2/quill.snow.css
  .ql-container {
    box-sizing: border-box;
    font-size: var(--font-size-base);
    height: 100%;
    margin: 0;
    position: relative
  }

  .ql-container.ql-disabled .ql-tooltip {
    visibility: hidden
  }

  .ql-container:not(.ql-disabled) li[data-list=checked]>.ql-ui,
  .ql-container:not(.ql-disabled) li[data-list=unchecked]>.ql-ui {
    cursor: pointer
  }

  .ql-clipboard {
    left: -100000px;
    height: 1px;
    overflow-y: hidden;
    position: absolute;
    top: 50%
  }

  .ql-clipboard p {
    margin: 0;
    padding: 0
  }

  .ql-editor {
    box-sizing: border-box;
    counter-reset: list-0 list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    line-height: 1.42;
    height: 100%;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 2;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;

    >* {
      cursor: text
    }

    p,
    ol,
    pre,
    blockquote,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      padding: 0
    }

    @supports (counter-set:none) {

      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        counter-set: list-0 list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9
      }
    }

    @supports not (counter-set:none) {

      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        counter-reset: list-0 list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9
      }
    }

    table {
      border-collapse: collapse
    }

    td {
      border: 1px solid #000;
      padding: 2px 5px
    }

    ol,
    ul {
      padding-left: 1.5em
    }

    li {
      position: relative
    }

    li>.ql-ui:before {
      display: inline-block;
      margin-left: -1.5em;
      margin-right: .3em;
      text-align: right;
      white-space: nowrap;
      width: 1.2em
    }

    li[data-list=checked]>.ql-ui,
    li[data-list=unchecked]>.ql-ui {
      color: #777
    }

    li[data-list=bullet]>.ql-ui:before {
      content: '\2022'
    }

    li[data-list=checked]>.ql-ui:before {
      content: '\2611'
    }

    li[data-list=unchecked]>.ql-ui:before {
      content: '\2610'
    }

    @supports (counter-set:none) {
      li[data-list] {
        counter-set: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9
      }
    }

    @supports not (counter-set:none) {
      li[data-list] {
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9
      }
    }

    li[data-list=ordered] {
      counter-increment: list-0
    }

    li[data-list=ordered]>.ql-ui:before {
      content: counter(list-0, decimal) '. '
    }

    li[data-list=ordered].ql-indent-1 {
      counter-increment: list-1
    }

    li[data-list=ordered].ql-indent-1>.ql-ui:before {
      content: counter(list-1, lower-alpha) '. '
    }

    @supports (counter-set:none) {
      li[data-list].ql-indent-1 {
        counter-set: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9
      }
    }

    @supports not (counter-set:none) {
      li[data-list].ql-indent-1 {
        counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9
      }
    }

    li[data-list=ordered].ql-indent-2 {
      counter-increment: list-2
    }

    li[data-list=ordered].ql-indent-2>.ql-ui:before {
      content: counter(list-2, lower-roman) '. '
    }

    @supports (counter-set:none) {
      li[data-list].ql-indent-2 {
        counter-set: list-3 list-4 list-5 list-6 list-7 list-8 list-9
      }
    }

    @supports not (counter-set:none) {
      li[data-list].ql-indent-2 {
        counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9
      }
    }

    li[data-list=ordered].ql-indent-3 {
      counter-increment: list-3
    }

    li[data-list=ordered].ql-indent-3>.ql-ui:before {
      content: counter(list-3, decimal) '. '
    }

    @supports (counter-set:none) {
      li[data-list].ql-indent-3 {
        counter-set: list-4 list-5 list-6 list-7 list-8 list-9
      }
    }

    @supports not (counter-set:none) {
      li[data-list].ql-indent-3 {
        counter-reset: list-4 list-5 list-6 list-7 list-8 list-9
      }
    }

    li[data-list=ordered].ql-indent-4 {
      counter-increment: list-4
    }

    li[data-list=ordered].ql-indent-4>.ql-ui:before {
      content: counter(list-4, lower-alpha) '. '
    }

    @supports (counter-set:none) {
      li[data-list].ql-indent-4 {
        counter-set: list-5 list-6 list-7 list-8 list-9
      }
    }

    @supports not (counter-set:none) {
      li[data-list].ql-indent-4 {
        counter-reset: list-5 list-6 list-7 list-8 list-9
      }
    }

    li[data-list=ordered].ql-indent-5 {
      counter-increment: list-5
    }

    li[data-list=ordered].ql-indent-5>.ql-ui:before {
      content: counter(list-5, lower-roman) '. '
    }

    @supports (counter-set:none) {
      li[data-list].ql-indent-5 {
        counter-set: list-6 list-7 list-8 list-9
      }
    }

    @supports not (counter-set:none) {
      li[data-list].ql-indent-5 {
        counter-reset: list-6 list-7 list-8 list-9
      }
    }

    li[data-list=ordered].ql-indent-6 {
      counter-increment: list-6
    }

    li[data-list=ordered].ql-indent-6>.ql-ui:before {
      content: counter(list-6, decimal) '. '
    }

    @supports (counter-set:none) {
      li[data-list].ql-indent-6 {
        counter-set: list-7 list-8 list-9
      }
    }

    @supports not (counter-set:none) {
      li[data-list].ql-indent-6 {
        counter-reset: list-7 list-8 list-9
      }
    }

    li[data-list=ordered].ql-indent-7 {
      counter-increment: list-7
    }

    li[data-list=ordered].ql-indent-7>.ql-ui:before {
      content: counter(list-7, lower-alpha) '. '
    }

    @supports (counter-set:none) {
      li[data-list].ql-indent-7 {
        counter-set: list-8 list-9
      }
    }

    @supports not (counter-set:none) {
      li[data-list].ql-indent-7 {
        counter-reset: list-8 list-9
      }
    }

    li[data-list=ordered].ql-indent-8 {
      counter-increment: list-8
    }

    li[data-list=ordered].ql-indent-8>.ql-ui:before {
      content: counter(list-8, lower-roman) '. '
    }

    @supports (counter-set:none) {
      li[data-list].ql-indent-8 {
        counter-set: list-9
      }
    }

    @supports not (counter-set:none) {
      li[data-list].ql-indent-8 {
        counter-reset: list-9
      }
    }

    li[data-list=ordered].ql-indent-9 {
      counter-increment: list-9
    }

    li[data-list=ordered].ql-indent-9>.ql-ui:before {
      content: counter(list-9, decimal) '. '
    }

    .ql-indent-1:not(.ql-direction-rtl) {
      padding-left: 3em
    }

    li.ql-indent-1:not(.ql-direction-rtl) {
      padding-left: 4.5em
    }

    .ql-indent-1.ql-direction-rtl.ql-align-right {
      padding-right: 3em
    }

    li.ql-indent-1.ql-direction-rtl.ql-align-right {
      padding-right: 4.5em
    }

    .ql-indent-2:not(.ql-direction-rtl) {
      padding-left: 6em
    }

    li.ql-indent-2:not(.ql-direction-rtl) {
      padding-left: 7.5em
    }

    .ql-indent-2.ql-direction-rtl.ql-align-right {
      padding-right: 6em
    }

    li.ql-indent-2.ql-direction-rtl.ql-align-right {
      padding-right: 7.5em
    }

    .ql-indent-3:not(.ql-direction-rtl) {
      padding-left: 9em
    }

    li.ql-indent-3:not(.ql-direction-rtl) {
      padding-left: 10.5em
    }

    .ql-indent-3.ql-direction-rtl.ql-align-right {
      padding-right: 9em
    }

    li.ql-indent-3.ql-direction-rtl.ql-align-right {
      padding-right: 10.5em
    }

    .ql-indent-4:not(.ql-direction-rtl) {
      padding-left: 12em
    }

    li.ql-indent-4:not(.ql-direction-rtl) {
      padding-left: 13.5em
    }

    .ql-indent-4.ql-direction-rtl.ql-align-right {
      padding-right: 12em
    }

    li.ql-indent-4.ql-direction-rtl.ql-align-right {
      padding-right: 13.5em
    }

    .ql-indent-5:not(.ql-direction-rtl) {
      padding-left: 15em
    }

    li.ql-indent-5:not(.ql-direction-rtl) {
      padding-left: 16.5em
    }

    .ql-indent-5.ql-direction-rtl.ql-align-right {
      padding-right: 15em
    }

    li.ql-indent-5.ql-direction-rtl.ql-align-right {
      padding-right: 16.5em
    }

    .ql-indent-6:not(.ql-direction-rtl) {
      padding-left: 18em
    }

    li.ql-indent-6:not(.ql-direction-rtl) {
      padding-left: 19.5em
    }

    .ql-indent-6.ql-direction-rtl.ql-align-right {
      padding-right: 18em
    }

    li.ql-indent-6.ql-direction-rtl.ql-align-right {
      padding-right: 19.5em
    }

    .ql-indent-7:not(.ql-direction-rtl) {
      padding-left: 21em
    }

    li.ql-indent-7:not(.ql-direction-rtl) {
      padding-left: 22.5em
    }

    .ql-indent-7.ql-direction-rtl.ql-align-right {
      padding-right: 21em
    }

    li.ql-indent-7.ql-direction-rtl.ql-align-right {
      padding-right: 22.5em
    }

    .ql-indent-8:not(.ql-direction-rtl) {
      padding-left: 24em
    }

    li.ql-indent-8:not(.ql-direction-rtl) {
      padding-left: 25.5em
    }

    .ql-indent-8.ql-direction-rtl.ql-align-right {
      padding-right: 24em
    }

    li.ql-indent-8.ql-direction-rtl.ql-align-right {
      padding-right: 25.5em
    }

    .ql-indent-9:not(.ql-direction-rtl) {
      padding-left: 27em
    }

    li.ql-indent-9:not(.ql-direction-rtl) {
      padding-left: 28.5em
    }

    .ql-indent-9.ql-direction-rtl.ql-align-right {
      padding-right: 27em
    }

    li.ql-indent-9.ql-direction-rtl.ql-align-right {
      padding-right: 28.5em
    }

    li.ql-direction-rtl {
      padding-right: 1.5em
    }

    li.ql-direction-rtl>.ql-ui:before {
      margin-left: .3em;
      margin-right: -1.5em;
      text-align: left
    }

    table {
      table-layout: fixed;
      width: 100%
    }

    table td {
      outline: none
    }

    .ql-code-block-container {
      font-family: monospace
    }

    .ql-video {
      display: block;
      max-width: 100%
    }

    .ql-video.ql-align-center {
      margin: 0 auto
    }

    .ql-video.ql-align-right {
      margin: 0 0 0 auto
    }

    .ql-bg-black {
      background-color: #000
    }

    .ql-bg-red {
      background-color: #e60000
    }

    .ql-bg-orange {
      background-color: #f90
    }

    .ql-bg-yellow {
      background-color: #ff0
    }

    .ql-bg-green {
      background-color: #008a00
    }

    .ql-bg-blue {
      background-color: #06c
    }

    .ql-bg-purple {
      background-color: #93f
    }

    .ql-color-white {
      color: var(--color-white)
    }

    .ql-color-red {
      color: #e60000
    }

    .ql-color-orange {
      color: #f90
    }

    .ql-color-yellow {
      color: #ff0
    }

    .ql-color-green {
      color: #008a00
    }

    .ql-color-blue {
      color: #06c
    }

    .ql-color-purple {
      color: #93f
    }

    .ql-font-serif {
      font-family: Georgia, Times New Roman, serif
    }

    .ql-font-monospace {
      font-family: Monaco, Courier New, monospace
    }

    .ql-size-small {
      font-size: .75em
    }

    .ql-size-large {
      font-size: 1.5em
    }

    .ql-size-huge {
      font-size: 2.5em
    }

    .ql-direction-rtl {
      direction: rtl;
      text-align: inherit
    }

    .ql-align-center {
      text-align: center
    }

    .ql-align-justify {
      text-align: justify
    }

    .ql-align-right {
      text-align: right
    }

    .ql-ui {
      position: absolute
    }

    &.ql-blank::before {
      color: rgba(0, 0, 0, 0.6);
      content: attr(data-placeholder);
      font-style: italic;
      left: 15px;
      pointer-events: none;
      position: absolute;
      right: 15px
    }

    h1 {
      font-size: 2em
    }

    h2 {
      font-size: 1.5em
    }

    h3 {
      font-size: 1.17em
    }

    h4 {
      font-size: 1em
    }

    h5 {
      font-size: .83em
    }

    h6 {
      font-size: .67em
    }

    a {
      text-decoration: underline
    }

    blockquote {
      border-left: 4px solid var(--input-border-color);
      margin-bottom: 5px;
      margin-top: 5px;
      padding-left: 16px
    }

    code,
    .ql-code-block-container {
      background-color: #f0f0f0;
      border-radius: 3px
    }

    .ql-code-block-container {
      margin-bottom: 5px;
      margin-top: 5px;
      padding: 5px 10px
    }

    code {
      font-size: 85%;
      padding: 2px 4px
    }

    .ql-code-block-container {
      background-color: #23241f;
      color: #f8f8f2;
      overflow: visible
    }

    img {
      cursor: pointer;
      max-width: 100%;

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px
      }
    }
  }

  .ql-hidden {
    display: none
  }

  .ql-out-bottom,
  .ql-out-top {
    visibility: hidden
  }

  .ql-tooltip {
    position: absolute;
    transform: translateY(10px)
  }

  .ql-tooltip a {
    cursor: pointer;
    text-decoration: none
  }

  .ql-tooltip.ql-flip {
    transform: translateY(-10px)
  }

  .ql-formats {
    display: inline-block;
    vertical-align: middle
  }

  .ql-formats:after {
    clear: both;
    content: '';
    display: table
  }

  .ql-stroke {
    fill: none;
    stroke: #444;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2
  }

  .ql-stroke-miter {
    fill: none;
    stroke: #444;
    stroke-miterlimit: 10;
    stroke-width: 2
  }

  .ql-fill,
  .ql-stroke.ql-fill {
    fill: #444
  }

  .ql-empty {
    fill: none
  }

  .ql-even {
    fill-rule: evenodd
  }

  .ql-thin,
  .ql-stroke.ql-thin {
    stroke-width: 1
  }

  .ql-transparent {
    opacity: .4
  }

  .ql-direction svg:last-child {
    display: none
  }

  .ql-direction.ql-active svg:last-child {
    display: inline
  }

  .ql-direction.ql-active svg:first-child {
    display: none
  }

  .ql-picker {
    display: inline-block;
    height: 24px;
    position: relative;
    vertical-align: middle
  }

  .ql-picker-label {
    cursor: pointer;
    display: inline-block;
    height: 100%;
    position: relative;
    width: 100%
  }

  .ql-picker-label::before {
    display: inline-block;
    line-height: 22px
  }

  .ql-picker-options {
    background-color: var(--color-white);
    display: none;
    min-width: 100%;
    padding: 4px 8px;
    position: absolute;
    white-space: nowrap
  }

  .ql-picker-options .ql-picker-item {
    color: var(--text-color);
    cursor: pointer;
    display: block;
    padding-bottom: 5px;
    padding-top: 5px;
    text-align: left;

    &:hover {
      color: var(--info-color);
    }
  }

  .ql-picker.ql-expanded .ql-picker-label {
    z-index: 2
  }

  .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    fill: var(--input-border-color)
  }

  .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: var(--input-border-color)
  }

  .ql-picker.ql-expanded .ql-picker-options {
    display: block;
    margin-top: -1px;
    top: 100%;
    z-index: 1
  }

  .ql-color-picker .ql-picker-label svg,
  .ql-icon-picker .ql-picker-label svg {
    right: 4px
  }

  .ql-icon-picker .ql-picker-options {
    padding: 4px 0
  }

  .ql-icon-picker .ql-picker-item {
    height: 24px;
    width: 24px;
    padding: 2px 4px
  }

  .ql-color-picker .ql-picker-options {
    padding: 3px 5px;
    width: 152px
  }

  .ql-color-picker .ql-picker-item {
    border: 1px solid transparent;
    float: left;
    height: 16px;
    margin: 2px;
    padding: 0;
    width: 16px
  }

  .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    display: none;
  }

  .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=''])::before,
  .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=''])::before,
  .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=''])::before,
  .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=''])::before,
  .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=''])::before,
  .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=''])::before {
    content: attr(data-label)
  }

  .ql-picker.ql-header {
    width: 98px
  }

  .ql-picker.ql-header .ql-picker-label::before,
  .ql-picker.ql-header .ql-picker-item::before {
    content: 'Normal'
  }

  .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
  .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: 'Heading 1'
  }

  .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
  .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: 'Heading 2'
  }

  .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
  .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: 'Heading 3'
  }

  .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
  .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: 'Heading 4'
  }

  .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
  .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: 'Heading 5'
  }

  .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
  .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: 'Heading 6'
  }

  .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    font-size: 2em
  }

  .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    font-size: 1.5em
  }

  .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    font-size: 1.17em
  }

  .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    font-size: 1em
  }

  .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    font-size: .83em
  }

  .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    font-size: .67em
  }

  .ql-picker.ql-font {
    width: 108px
  }

  .ql-picker.ql-font .ql-picker-label::before,
  .ql-picker.ql-font .ql-picker-item::before {
    content: 'Sans Serif'
  }

  .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
  .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
    content: 'Serif'
  }

  .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
  .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
    content: 'Monospace'
  }

  .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
    font-family: Georgia, Times New Roman, serif
  }

  .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
    font-family: Monaco, Courier New, monospace
  }

  .ql-picker.ql-size .ql-picker-label::before,
  .ql-picker.ql-size .ql-picker-item::before {
    content: 'Normal'
  }

  .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
  .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
    content: 'Small'
  }

  .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
  .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    content: 'Large'
  }

  .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
  .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    content: 'Huge'
  }

  .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
    font-size: 1rem;
  }

  .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    font-size: 1.8rem;
  }

  .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    font-size: 3.2rem;
  }

  .ql-color-picker.ql-background .ql-picker-item {
    background-color: var(--color-white)
  }

  .ql-color-picker.ql-color .ql-picker-item {
    background-color: #000
  }

  .ql-code-block-container {
    position: relative
  }

  .ql-code-block-container .ql-ui {
    right: 5px;
    top: 5px
  }

  .ql-toolbar {
    border: 1px solid var(--input-border-color);
    border-bottom: 0;
    box-sizing: border-box;
    padding: 5px;
    position: relative;
    z-index: 10;

    +.ql-container {
      border-top: 0
    }

    &:after {
      clear: both;
      content: '';
      display: table
    }

    .ql-formats {
      margin-right: 15px
    }

    .ql-picker-label {
      border: 1px solid transparent
    }

    .ql-picker-options {
      border: 1px solid transparent;
      box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px
    }

    .ql-picker.ql-expanded .ql-picker-label {
      border-color: var(--input-border-color)
    }

    .ql-picker.ql-expanded .ql-picker-options {
      border-color: var(--input-border-color)
    }

    .ql-color-picker .ql-picker-item.ql-selected,
    .ql-color-picker .ql-picker-item:hover {
      border-color: #000
    }

    .btn {
      background: none;
      border: none;
      cursor: pointer;
      display: inline-block;
      height: 24px;
    }

    .btn svg {
      float: left;
      height: 100%
    }

    .btn:active:hover {
      outline: none
    }

    input.ql-image[type=file] {
      display: none
    }

    .ql-active i {
      color: var(--info-color);
    }

    .btn:hover {
      color: var(--info-color);
    }
  }

  .ql-tooltip {
    align-items: center;
    background-color: var(--color-white);
    border: 1px solid var(--input-border-color);
    box-shadow: 0 0 5px #ddd;
    display: flex;
    padding: 4px;
    white-space: nowrap;

    &.ql-hidden {
      display: none;
    }
  }

  .ql-tooltip::before {
    background: var(--muted-color);
    border-right: 1px solid var(--input-border-color);
    content: "";
    display: inline-block;
    height: 18px;
    margin: 0 4px;
    mask-image: var(--icn-link-mask-image);
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    width: 18px;
  }

  .ql-tooltip input[type=text] {
    display: none;
    border: 1px solid var(--input-border-color);
    font-size: var(--font-size-base);
    height: 26px;
    margin: 0 4px;
    padding: 3px 5px;
    max-width: 300px;
    min-width: 170px;
  }

  .ql-tooltip a.ql-preview {
    display: inline-block;
    margin: 0 4px;
    max-width: 300px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }

  .ql-action,
  .ql-remove {
    align-items: center;
    display: inline-flex;
    justify-content: center;
  }

  .ql-tooltip a.ql-action::after {
    background: var(--success-color);
    border-right: 1px solid var(--input-border-color);
    content: "";
    display: inline-block;
    height: 18px;
    margin: 0 4px;
    mask-image: var(--icn-edit-mask-image);
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    width: 18px;
  }

  .ql-tooltip a.ql-remove::before {
    background: var(--danger-color);
    border-right: 1px solid var(--input-border-color);
    content: "";
    display: inline-block;
    height: 18px;
    margin: 0 4px;
    mask-image: var(--icn-xmark-mask-image);
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    width: 18px;
  }

  .ql-tooltip.ql-editing a.ql-preview,
  .ql-tooltip.ql-editing a.ql-remove {
    display: none
  }

  .ql-tooltip.ql-editing input[type=text] {
    display: inline-block
  }

  .ql-tooltip.ql-editing a.ql-action::after {
    background: var(--success-color);
    border-right: 1px solid var(--input-border-color);
    content: "";
    display: inline-block;
    height: 18px;
    margin: 0 4px;
    mask-image: var(--icn-save-mask-image);
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    width: 18px;
  }

  .ql-tooltip[data-mode=link]::before {
    content: "Enter link:"
  }

  .ql-tooltip[data-mode=formula]::before {
    content: "Enter formula:"
  }

  .ql-tooltip[data-mode=video]::before {
    content: "Enter video:"
  }

  .ql-container {
    border: 1px solid var(--input-border-color);
    border-color: var(--input-border-color);
  }
}