.row {
  clear: both;
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(-1 * (var(--block-spacing) / 2));
}

[class*="col-"] {
  float: unset;
  max-width: 100%;
  min-height: unset;
  padding-left: calc(var(--block-spacing) / 2);
  padding-right: calc(var(--block-spacing) / 2);
}

.separated-rows:not(:first-child) {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid var(--color-gray);
}