.pages-layout,
.partner-or-tenant-logo,
.nav-controls-subwrapper,
.app-menu-list {
  transition: all 0.35s ease;
}

.pages-layout {
  height: 100%;
  margin-left: var(--nav-controls-width);
  max-height: 100%;
  position: relative;
  width: calc(100% - var(--nav-controls-width));
  z-index: 11;

  &[data-show-virtual-keyboard="true"] {
    --virtual-keyboard-height: 223px;
    padding-bottom: var(--virtual-keyboard-height);

    .nav-controls {
      padding-bottom: var(--virtual-keyboard-height);
    }

    #virtual-keyboard-toggle-btn i {
      background: var(--nav-controls-text-color);
      color: var(--nav-controls-background);
      border-radius: 100%;
    }
  }

  &[data-show-app-menu="true"] {
    .app-controls-wrapper .app-menu .app-menu-list {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  &[data-is-loading-logos="true"] {
    &:before {
      animation: logo-anim 0.8s linear infinite alternate-reverse;
      background-image: linear-gradient(100deg, rgba(#cccccc, 0) 0, rgba(#cccccc, 0.2) 20%, rgba(#cccccc, 0.5) 60%, rgba(#cccccc, 0));
      content: "";
      height: 3px;
      position: fixed;
      top: 0;
      transform: translateX(calc(-1.5 * var(--nav-controls-width)));
      width: var(--nav-controls-width);
      z-index: 10000;
    }
  }

  .global-search {
    background: var(--color-white);
    border: var(--card-border);
    border-radius: var(--app-controls-dropdown-border-radius);
    box-shadow: var(--app-controls-dropdown-box-shadow);
    display: none;
    max-height: calc(100vh - var(--app-controls-height));
    max-width: 100%;
    padding: var(--card-content-padding);
    position: fixed;
    top: var(--app-controls-height);
    resize: horizontal;
    right: 0;
    z-index: 20000;

    .global-search-submit-search-btn {
      min-height: var(--textarea-min-height);
    }

    .global-search-results-list {
      max-height: 50vh;
      overflow: auto;
    }

    .global-search-result {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &[data-show-global-search-input="true"] {
    .global-search {
      display: block;
    }

    #global-search-btn i {
      background: var(--nav-controls-text-color);
      color: var(--nav-controls-background);
      border-radius: 100%;
    }
  }
}

.global-search-page {
  .global-search {
    box-shadow: unset;
    display: block;
    max-height: unset;
    max-width: unset;
    position: static;
    resize: none;

    .global-search-results-list {
      max-height: unset;
    }
  }
}

.overflow-arrow-left,
.overflow-arrow-right {
  display: none;
}

@keyframes logo-anim {
  to {
    transform: translateX(calc(-0.5 * var(--nav-controls-width)));
  }
}

.partner-or-tenant-logo {
  background: var(--nav-controls-logo-partner-or-tenant-background);
  left: 0;
  height: var(--app-controls-height);
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: var(--nav-controls-width);

  img {
    height: var(--app-controls-height);
    object-fit: contain;
    width: var(--nav-controls-width);
  }

  .navbar-toggle {
    border: 0;
    display: none;

    &:focus,
    &:hover {
      background: transparent;
    }

    .icon-bar {
      position: relative;
    }

    .icon-bar {
      background-color: var(--app-controls-items-color);

      &:nth-child(2) {
        animation: 500ms 0s topbar-back forwards;
        top: 0px;
      }

      &:nth-child(3) {
        opacity: 1;
      }

      &:nth-child(4) {
        animation: 500ms 0s bottombar-back forwards;
        bottom: 0px;
      }
    }
  }
}

.app-controls-wrapper {
  align-items: center;
  background-color: var(--app-controls-background);
  border-bottom: var(--app-controls-border-bottom);
  display: flex;
  height: var(--app-controls-height);
  padding: var(--container-padding);
  width: 100%;

  .app-controls {
    align-items: center;
    display: flex;
    flex-grow: 1;
    position: relative;
    justify-content: flex-end;
  }

  .app-control-btn {
    background: transparent;
    border: 0;
    color: var(--app-controls-items-color);
    height: var(--wcag-aa-min-size);
    margin: 0;
    padding: 0;
    transition: all 0.15s;
    width: 30px;

    // this is necessary to override the btn:hover color
    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      color: var(--app-controls-items-color);
    }

    i {
      color: var(--app-controls-items-color);
    }
  }

  .app-menu {
    --user-block-height: 40px;
    align-items: center;
    display: flex;
    position: relative;

    #global-search-btn {
      display: none;
    }

    .user-block {
      align-items: center;
      cursor: pointer;
      color: var(--app-controls-items-color);
      display: flex;
      height: var(--user-block-height);
      justify-content: center;

      &:hover .username {
        text-decoration: underline;
      }

      .orbiting-point {
        animation: orbit-anim 0.8s linear infinite;
        background-color: var(--app-controls-items-color);
        height: 2px;
        opacity: 0;
        position: absolute;
        width: 2px;
      }

      @keyframes orbit-anim {
        from {
          transform: rotate(0deg) translateX(12px) rotate(0deg);
        }

        to {
          transform: rotate(360deg) translateX(12px) rotate(-360deg);
        }
      }

      &[data-is-loading-user-photo="true"] {
        .orbiting-point {
          opacity: 0.85;
        }
      }

      --user-photo-avatar-size: 24px;

      .user-photo {
        border-radius: 50%;
        height: var(--user-photo-avatar-size);
        width: var(--user-photo-avatar-size);
      }

      .user-avatar {
        align-items: center;
        border: 1px solid var(--app-controls-items-color);
        border-radius: 50%;
        display: flex;
        font-size: 1.2rem;
        justify-content: center;
        height: var(--user-photo-avatar-size);
        line-height: 0;
        width: var(--user-photo-avatar-size);
      }

      .user-block-wrapper {
        color: var(--app-controls-items-color);
        margin: 0 4px;
        max-width: 100px;
      }

      .username,
      .user-first-last-name {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .app-menu-list {
      background-color: var(--color-white);
      border: var(--card-border);
      border-radius: var(--app-controls-dropdown-border-radius);
      box-shadow: var(--app-controls-dropdown-box-shadow);
      min-width: 200px;
      font-size: var(--app-controls-dropdown-font-size);
      opacity: 0;
      position: absolute;
      right: 0;
      top: 100%;
      transform: translateY(-20px);
      visibility: hidden;
      z-index: 1000;

      &::before {
        border-bottom: 11px solid var(--color-gray);
        top: -11px;
      }

      &::after {
        border-bottom: 11px solid var(--color-white);
        top: -10px;
      }

      &::before,
      &::after {
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        content: "";
        display: inline-block;
        left: auto;
        position: absolute;
        right: 0;
      }

      .user-profile {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .profile-user-name {
        display: none;
      }

      .app-menu-item {
        align-items: center;
        color: var(--text-color);
        cursor: pointer;
        display: flex;
        height: var(--app-controls-dropdown-item-height);
        padding-right: var(--block-spacing);
        white-space: nowrap;

        &:first-child {
          border-radius: var(--app-controls-dropdown-border-radius) var(--app-controls-dropdown-border-radius) 0 0;
        }

        &:last-child {
          border-radius: 0 0 var(--app-controls-dropdown-border-radius) var(--app-controls-dropdown-border-radius);
        }

        .app-menu-item-icon {
          margin: 0 calc(var(--app-controls-dropdown-icon-margin) / 2);
          text-align: center;
          width: 30px;
        }

        &:hover {
          background-color: #f5f5f5;
        }
      }

      .app-menu-header {
        cursor: auto;

        &:hover {
          background-color: transparent;
        }
      }

      .app-controls-sub-menu {
        max-height: 20vh;
        overflow-y: auto;

        .app-menu-item {
          border-radius: 0;
        }
      }
    }
  }
}

.nav-controls-subwrapper {
  background: var(--nav-controls-background);
  border-right: var(--nav-controls-border-right);
  display: flex;
  flex-direction: column;
  height: calc(100% - var(--nav-controls-logo-partner-or-tenant-height));
  left: 0;
  max-width: 100%;
  overflow-x: hidden;
  position: fixed;
  top: var(--nav-controls-logo-partner-or-tenant-height);
  width: var(--nav-controls-width);
  z-index: 1000;

  .offline-message {
    color: var(--nav-controls-text-color);
    font-size: 1.2rem;
    margin-top: 2px;
    text-align: center;
  }
}

.nav-controls {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: overlay;
  padding: var(--block-spacing) 0;
  padding-right: var(--scrollbar-browser-width);
  width: calc(100% + var(--scrollbar-browser-width));

  i {
    font-size: var(--nav-controls-link-icon-font-size);
    height: 26px;
    margin-right: var(--nav-controls-left-element-margin-right);
    min-width: 30px;
    width: 30px;
  }

  .nav-block {
    min-height: var(--nav-controls-item-min-height);
    overflow: hidden;
    width: var(--nav-controls-width);

    &:hover {
      .nav-control {
        background: var(--nav-controls-nav-hover);
        color: var(--nav-controls-text-color);
        opacity: 1;
      }
    }
  }

  .nav-control {
    align-items: center;
    color: var(--nav-controls-text-color);
    cursor: pointer;
    display: flex;
    font-size: var(--nav-controls-category-font-size);
    font-weight: var(--nav-controls-category-font-weight);
    margin: var(--nav-controls-link-margin);
    min-height: var(--nav-controls-item-min-height);
    padding: var(--nav-controls-link-padding);
    text-transform: var(--nav-controls-category-text-transform);

    &[data-show-nav-control="true"] {
      b.caret {
        transform: rotate(0deg);
      }
    }
  }

  .nav-subcontrols {
    height: 0;
    transition: all 0.45s ease;
  }

  .nav-subcontrol {
    color: var(--nav-controls-text-color);
    display: flex;
    padding: var(--nav-controls-link-padding);
    margin: var(--nav-controls-link-margin);
    position: relative;

    &::after {
      background: var(--nav-controls-text-color);
      bottom: 0;
      content: "";
      left: var(--nav-controls-line-v-left);
      height: calc(100% + var(--nav-controls-link-margin-bottom));
      opacity: 0.55;
      position: absolute;
      top: 0;
      width: 1px;
    }

    &:last-child::after {
      height: 50%;
    }

    .nav-text {
      font-size: var(--nav-controls-category-subcontrol-font-size);
      padding-left: 45px;

      &::after {
        background: var(--nav-controls-text-color);
        content: "";
        height: 1px;
        left: var(--nav-controls-line-v-left);
        opacity: 0.55;
        position: absolute;
        top: var(--nav-controls-line-h-left);
        width: 6px;
      }
    }
  }

  .nav-divider {
    background: var(--nav-controls-text-color);
    height: 1px;
    margin: calc(var(--nav-controls-item-min-height) / 2) calc(var(--nav-controls-link-padding-h) + var(--nav-controls-link-margin-h));
    opacity: 0.55;
  }

  .nav-text {
    align-items: center;
    display: flex;
    flex-grow: 1;
    font-size: var(--nav-controls-category-font-size);
    justify-content: space-between;
    min-width: 147px;
    word-wrap: break-word;

    .nav-text-inner {
      display: inline-block;
      overflow: hidden;
    }
  }

  .nav-control,
  .nav-subcontrol {
    border-radius: var(--nav-controls-link-border-radius);

    &:hover,
    &.active {
      background: var(--nav-controls-nav-hover);
    }

    &.active {
      color: var(--nav-controls-link-active-text-color, var(--nav-controls-text-color));
      background: var(--nav-controls-link-active-background);
      transition: all 0.15s linear;
    }

    &:focus {
      outline: var(--accessibility-outline-nav-control);
      outline-offset: var(--accessibility-outline-offset-inset);
    }
  }
}

.mobile-ui-overlay {
  background: var(--overlay-background);
  height: calc(100% - var(--app-controls-height));
  left: 0;
  opacity: 0;
  position: fixed;
  transition: all 0.1s;
  top: var(--app-controls-height);
  transform: translateX(-100vw);
  width: 100%;
  z-index: 100;
}

@media (max-width: $screen-sm) {
  .pages-layout {
    margin-left: 0;
    margin-top: var(--app-controls-height);
    width: 100%;

    &[data-show-mobile-menu="true"] {
      .navbar-toggle .icon-bar {
        &:nth-child(2) {
          animation: 500ms 0s topbar-x forwards;
          top: 6px;
        }

        &:nth-child(3) {
          opacity: 0;
        }

        &:nth-child(4) {
          animation: 500ms 0s bottombar-x forwards;
          bottom: 6px;
        }
      }

      .mobile-ui-overlay {
        opacity: 1;
        transform: translateX(0);
      }

      .nav-controls-subwrapper {
        transform: translateX(calc(-1 * var(--nav-controls-width)));
        width: unset;
      }
    }

    &[data-show-app-menu="true"] {
      .mobile-ui-overlay {
        opacity: 1;
        transform: translateX(0);
      }

      .app-controls-wrapper {
        .app-controls {
          transform: translateX(calc(var(--nav-controls-width)));
        }

        .app-menu .app-menu-list {
          transform: translateX(calc(-1 * var(--nav-controls-width)));
          width: unset;
        }
      }
    }
  }

  .partner-or-tenant-logo {
    background: var(--app-controls-background);
    border-bottom: unset;
    display: flex;
    justify-content: space-between;
    padding: 0 var(--block-spacing);
    width: 100%;
    z-index: 200;

    a {
      align-items: center;
      display: flex;
      height: 100%;
    }

    img {
      height: calc(var(--app-controls-height) - 1px);
      max-width: calc(50vw - var(--block-spacing));
      object-fit: contain;
      object-position: left;
    }

    .navbar-toggle {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 30px;
    }
  }

  .app-controls-wrapper {
    background: transparent;
    border: 0;
    padding: 0;
    position: fixed;
    right: calc(32px + var(--block-spacing));
    top: 0;
    width: auto;
    z-index: 100000;

    .app-controls {
      flex-direction: column-reverse;
      position: fixed;
      left: calc(-1 * var(--nav-controls-width));
      top: var(--nav-controls-logo-partner-or-tenant-height);
      transform: unset;
      width: 40px;
      z-index: 1;

      .app-control-btn {
        background-color: var(--primary-color);
        border-radius: 100%;
        color: var(--color-white);
        display: flex;
        font-size: var(--control-btn-font-size);
        height: var(--control-btn-hw);
        margin: 5px 0;
        width: var(--control-btn-hw);

        i {
          color: var(--nav-controls-text-color);
        }
      }

      #global-search-btn {
        display: none;
      }
    }

    .app-menu {
      #global-search-btn {
        display: block;
        height: var(--user-block-height);
      }

      .user-block {
        padding-right: 0;
        width: 30px;

        .user-block-wrapper {
          display: none;
        }
      }

      .app-menu-list {
        background: var(--nav-controls-background);
        border: 0;
        border-radius: 0;
        height: 100%;
        max-height: calc(100% - var(--app-controls-height));
        overflow-y: auto;
        padding: var(--block-spacing) 0;
        position: fixed;
        right: calc(-1 * var(--nav-controls-width));
        top: var(--nav-controls-logo-partner-or-tenant-height);
        transform: unset;
        width: var(--nav-controls-width);

        &::before,
        &::after {
          display: none;
        }

        .app-menu-item {
          border-radius: var(--app-controls-dropdown-border-radius);
          color: var(--nav-controls-text-color);
          font-size: 1.2rem;
          font-weight: bold;
          height: var(--nav-controls-item-min-height);
          line-height: 1;
          margin: var(--nav-controls-link-margin);
          padding: var(--nav-controls-link-padding);
          text-transform: uppercase;

          &:hover {
            background: var(--nav-controls-nav-hover);
          }

          .app-menu-item-icon {
            color: var(--nav-controls-text-color);
            margin: 0 var(--app-controls-dropdown-icon-margin) 0 0;
          }
        }

        .divider,
        .divider-light {
          display: none;
        }

        .profile-generic {
          display: none;
        }

        .profile-user-name {
          display: block;
        }
      }
    }
  }

  .nav-controls-subwrapper {
    border-right: unset;
    left: unset;
    right: calc(-1 * var(--nav-controls-width));
    top: var(--nav-controls-logo-partner-or-tenant-height);
    z-index: 200;
  }

  .app-controls-sub-menu {
    max-height: 300px;
  }
}

@keyframes topbar-back {
  0% {
    top: 6px;
    transform: rotate(135deg);
  }

  45% {
    transform: rotate(-10deg);
  }

  75% {
    transform: rotate(5deg);
  }

  100% {
    top: 0px;
    transform: rotate(0);
  }
}

@keyframes bottombar-back {
  0% {
    bottom: 6px;
    transform: rotate(-135deg);
  }

  45% {
    transform: rotate(10deg);
  }

  75% {
    transform: rotate(-5deg);
  }

  100% {
    bottom: 0px;
    transform: rotate(0);
  }
}

@keyframes topbar-x {
  0% {
    top: 0px;
    transform: rotate(0deg);
  }

  45% {
    top: 6px;
    transform: rotate(145deg);
  }

  75% {
    transform: rotate(130deg);
  }

  100% {
    transform: rotate(135deg);
  }
}

@keyframes bottombar-x {
  0% {
    bottom: 0px;
    transform: rotate(0deg);
  }

  45% {
    bottom: 6px;
    transform: rotate(-145deg);
  }

  75% {
    transform: rotate(-130deg);
  }

  100% {
    transform: rotate(-135deg);
  }
}