.tooltip {
  &:hover {
    cursor: help;
  }

  &.bottom {
    margin-bottom: -5px;

    .tooltip-arrow {
      border-bottom-color: var(--color-black-lightest);
    }
  }

  &.top {
    margin-top: -5px;

    .tooltip-arrow {
      border-top-color: var(--color-black-lightest);
    }
  }

  &.left {
    margin-left: -5px;

    .tooltip-arrow {
      border-left-color: var(--color-black-lightest);
    }
  }

  &.right {
    margin-right: -5px;

    .tooltip-arrow {
      border-right-color: var(--color-black-lightest);
    }
  }
}

.tooltip-inner {
  background-color: var(--color-black-lightest);
  color: var(--color-white);
  max-width: $screen-xs;
  text-align: center;
}

@media (max-width: $screen-sm) {
  .tooltip {
    display: none;
  }
}