// Firefox has an old bug regarding the table border disappearing when cells have a background color
// that we cannot totally circumvent : https://bugzilla.mozilla.org/show_bug.cgi?id=688556

// from react-datasheet/lib/react-datasheet.css
span.data-grid-container,
span.data-grid-container:focus {
  outline: none;
}

.data-grid-container .data-grid {
  table-layout: fixed;
  border-collapse: collapse;
}

.data-grid-container .data-grid .cell.updated {
  background-color: rgba(0, 145, 253, 0.16);
  transition: background-color 0ms ease;
}

.data-grid-container .data-grid .cell {
  height: 17px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: cell;
  background-color: unset;
  transition: background-color 500ms ease;
  vertical-align: middle;
  text-align: right;
  border: 1px solid #ddd;
  padding: 0;
}

.data-grid-container .data-grid .cell.selected {
  border: 1px double rgb(33, 133, 208);
  transition: none;
  box-shadow: inset 0 -100px 0 rgba(33, 133, 208, 0.15);
}

.data-grid-container .data-grid .cell.read-only {
  background: whitesmoke;
  color: #999;
  text-align: center;
}

.data-grid-container .data-grid .cell>.text {
  padding: 2px 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.data-grid-container .data-grid .cell>input {
  outline: none !important;
  border: 2px solid rgb(33, 133, 208);
  text-align: right;
  width: calc(100% - 6px);
  height: 11px;
  background: none;
  display: block;
}

.data-grid-container .data-grid .cell {
  vertical-align: bottom;
}

.data-grid-container .data-grid .cell,
.data-grid-container .data-grid.wrap .cell,
.data-grid-container .data-grid.wrap .cell.wrap,
.data-grid-container .data-grid .cell.wrap,
.data-grid-container .data-grid.nowrap .cell.wrap,
.data-grid-container .data-grid.clip .cell.wrap {
  white-space: normal;
}

.data-grid-container .data-grid.nowrap .cell,
.data-grid-container .data-grid.nowrap .cell.nowrap,
.data-grid-container .data-grid .cell.nowrap,
.data-grid-container .data-grid.wrap .cell.nowrap,
.data-grid-container .data-grid.clip .cell.nowrap {
  white-space: nowrap;
  overflow-x: visible;
}

.data-grid-container .data-grid.clip .cell,
.data-grid-container .data-grid.clip .cell.clip,
.data-grid-container .data-grid .cell.clip,
.data-grid-container .data-grid.wrap .cell.clip,
.data-grid-container .data-grid.nowrap .cell.clip {
  white-space: nowrap;
  overflow-x: hidden;
}

.data-grid-container .data-grid .cell .value-viewer,
.data-grid-container .data-grid .cell .data-editor {
  display: block;
  overflow: hidden;
}

// end of external css

.math-sheet-component {
  display: block;
  overflow: auto;
  width: 100%;

  &.table-layout-initial {
    table {
      table-layout: initial;
    }
  }

  .cell-comment-hover {
    .cell-comment-icon {
      opacity: 0;
      transition: all 0.3s;
    }

    &:hover .cell-comment-icon {
      opacity: 1;
    }
  }
}

.data-grid-container .data-grid {
  margin: auto;
  width: 100%;

  tbody .row-handle.cell,
  thead .cell:not(.row-handle) {
    cursor: move;
  }

  tr {
    // this seems to have no visible impact and it conflicts with separator => commented out
    //    td.cell:not(.selected) {
    //      border: 1px solid var(--color-gray-lighter);
    //    }

    td.cell,
    th.cell {
      font-size: var(--font-size-base);
      vertical-align: middle;
    }
  }

  .cell {
    padding: 0 5px;
    transition: none;
    white-space: normal !important;

    &.cell-date {
      overflow: unset;
    }

    &.cell-select {
      overflow: unset;

      .select__control,
      .select__input-container {
        border: 0;
        height: 20px;
        margin-bottom: 0;
        margin-top: 0;
        min-height: unset;
        padding-bottom: 0;
        padding-top: 0;
      }
    }

    &.error {
      color: var(--danger-color) !important;
    }

    &.equation {
      position: relative;

      // this creates a small triangle in the top left corner of the cell
      // like in Excel when there's a message or an error in it
      &::before {
        border-color: var(--success-color) transparent transparent transparent;
        border-style: solid;
        border-width: 6px 6px 0 0;
        content: "";
        height: 0;
        left: 0;
        position: absolute;
        top: 0;
        width: 0;
        z-index: 2;
      }
    }

    &.row-handle {
      width: 1rem;
    }

    >input {
      background: none;
      outline: none !important;
      text-align: left;
      width: 100%;
    }

    >textarea {
      background: none;
      display: block;
      outline: none !important;
      text-align: left;
      width: 100%;
    }

    &.editing {
      padding: 0;

      textarea,
      input {
        border: 0;
        border-radius: 0;
        height: auto;
        padding: 0 5px;
      }

      textarea {
        padding: 5px;
      }
    }

    &.selected {
      box-shadow: unset;
      background-color: var(--info-background-color);
      border-color: var(--info-color);
    }

    &.separator.equation {
      border-top: 2px solid var(--color-black);
    }

    &.separator.total1 {
      border-bottom: 3px solid var(--color-black);
      font-weight: bold;
    }

    &.separator.total2 {
      border-bottom: 1px solid var(--color-black);
    }

    &.separator {
      border-top: 2px solid var(--color-black);
    }

    &.italic {
      font-style: italic;
    }

    &.left-align {
      text-align: left;
    }

    &.right-align {
      text-align: right !important;
    }

    &.align-center {
      text-align: center !important;
    }

    &.top-align {
      vertical-align: top;
    }

    &.green {
      background-color: var(--success-background-color) !important;
    }

    &.orange {
      background-color: var(--warning-background-color) !important;
    }

    &.red {
      background-color: var(--danger-background-color) !important;
    }

    &.read-only {
      background: var(--muted-background-color);
      background-clip: padding-box; // firefox fix
      color: var(--color-black-lightest);
      text-align: right;

      &.title1,
      &.title2,
      &.title3 {
        background: unset;
        color: var(--text-color);
        text-align: left;

        &.selected {
          background-color: var(--info-background-color);
        }
      }

      &.title1 {
        font-weight: bold;
      }

      &.title2 {
        font-weight: normal;
      }

      &.title3 {
        font-weight: normal;
        padding-left: 30px;
      }

      &.total1,
      &.total2 {
        background: unset;
        color: var(--text-color);
        font-weight: bold;
        text-align: left;
      }

      &.total1 {
        border-bottom: 3px solid var(--color-black-lightest);
        font-size: var(--font-size-base);
      }

      &.total2 {
        border-bottom: 1px solid var(--color-white);
      }

      &.paddingtop {
        padding-top: 20px;
      }
    }
  }

  // is it really used ?
  // there's no trace of it in the code and the react-datasheet lib
  .drop-target,
  thead .cell.read-only.drop-target {
    background: #6f86fc;
    color: var(--color-white);
    transition: none;
  }

  .Select-control,
  .Select-input,
  .Select-placeholder,
  .Select-clear,
  .Select-placeholder,
  .Select--single>.Select-control .Select-value {
    border-radius: 0;
    border: 0;
    font-size: 1.2rem;
    height: 15px;
    line-height: 12px;
    text-align: left;
  }

  .Select-control input {
    height: 16px;
    font-size: 1.2rem;
    padding: 0;
  }
}

@media only screen and (max-width: 600px) {
  .math-sheet-component .data-grid {
    min-width: 50rem;
  }
}