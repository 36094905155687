.entity-step {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  float: left;
  height: 20px;
  margin: 0 10px 5px 0;
  padding: 0 5px 0 15px;
  position: relative;

  .step-after {
    border-bottom: 9px solid transparent;
    border-left: 1rem solid;
    border-top: 9px solid transparent;
    bottom: 0;
    content: "";
    left: 0;
    height: 0;
    position: absolute;
    width: 0;
  }

  .step-before {
    border-bottom: 10px solid transparent;
    border-left: 10px solid;
    border-top: 10px solid transparent;
    bottom: -1px;
    content: "";
    height: 0;
    position: absolute;
    right: -1rem;
    width: 0;
  }

  .step-title {
    color: var(--color-white);
    font-size: 1.2rem;
    line-height: 18px;
  }

  .step-info {
    color: var(--color-black);
  }
}