// Classes written here must come from tailwind first then bootstrap (if not defined in tailwind).
// Specific custom reusable classes can also be written here but be sure of what you do.
// This is because these classes are used a lot externally in page / layout scripts.
// https://tailwindcss.com/docs/
// https://getbootstrap.com/docs/4.0/utilities
// Note : properties depending on left and right positioning must be also declared in rtl.scss

// Height, width and grid utilities use percentage as default unit.
// Font size, border width and line height use rem as default unit.
// Utilities ending with -theme can use whatever unit they want.
// When not declaring in a default unit, the unit must be specified in the name of the class.
@use "sass:math" as math;

.c-pointer {
  cursor: pointer;
}

.c-default {
  cursor: default;
}

.c-not-allowed {
  cursor: not-allowed;
}

.c-ew-resize {
  cursor: ew-resize;
}

.c-w-resize {
  cursor: w-resize;
}

.c-grab {
  cursor: grab;
}

.c-grabbing {
  cursor: grabbing;
}

.pointer-events-none {
  pointer-events: none;
}

.resize-none {
  resize: none !important;
}

.resize-y {
  resize: vertical !important;
}

.resize-x {
  resize: horizontal !important;
}

.height-auto {
  height: auto !important;
}

.height-100 {
  height: 100% !important;
}

.height-main-view {
  height: calc(100vh - var(--app-controls-height)) !important;
}

.width-auto {
  width: auto !important;
}

.max-h-14px {
  max-height: 14px !important;
}

.max-h-24px {
  max-height: var(--wcag-aa-min-size) !important;
}

.max-h-30px {
  max-height: 30px !important;
}

.max-h-50px {
  max-height: 50px !important;
}

.max-h-100px {
  max-height: 100px !important;
}

.max-h-200px {
  max-height: 200px !important;
}

.max-h-500px {
  max-height: 500px !important;
}

.max-h-1000px {
  max-height: 1000px !important;
}

.max-w-0 {
  max-width: 0 !important;
}

.max-w-24px {
  max-width: var(--wcag-aa-min-size) !important;
}

.max-w-50px {
  max-width: 50px !important;
}

.max-w-100px {
  max-width: 100px !important;
}

.max-w-100 {
  max-width: 100% !important;
}

.max-w-screen-xs {
  max-width: $screen-xs !important;
}

.max-w-screen-sm {
  max-width: $screen-sm !important;
}

.max-w-screen-md {
  max-width: $screen-md !important;
}

.max-w-screen-lg {
  max-width: $screen-lg !important;
}

.max-w-screen-xl {
  max-width: $screen-xl !important;
}

.max-w-screen-xxl {
  max-width: $screen-xxl !important;
}

.min-w-0 {
  min-width: 0px !important;
}

.min-w-24px {
  min-width: var(--wcag-aa-min-size) !important;
}

.min-w-40px {
  min-width: 40px !important;
}

.min-w-200px {
  min-width: 200px !important;
}

.min-w-xxs {
  min-width: $screen-xxs !important;
}

.min-h-24px {
  min-height: var(--wcag-aa-min-size) !important;
}

.min-h-30px {
  min-height: 30px !important;
}

.min-h-fit {
  min-height: fit-content !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.top-0 {
  top: 0px;
}

.top-1px {
  top: 1px;
}

.top-full {
  top: 100%;
}

.right-0 {
  right: 0px;
}

.right-1px {
  right: 1px;
}

.right-full {
  right: 100%;
}

.bottom-0 {
  bottom: 0px;
}

.bottom-5px {
  bottom: 5px;
}

.bottom-full {
  bottom: 100%;
}

.left-0 {
  left: 0px;
}

.left-1px {
  left: 1px;
}

.left-full {
  left: 100%;
}

.z-10 {
  z-index: 10;
}

.direction-ltr {
  direction: ltr;
}

.direction-rtl {
  direction: rtl;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-success {
  background-color: var(--success-color) !important;
}

.bg-info {
  background-color: var(--info-color) !important;
}

.bg-warning {
  background-color: var(--warning-color) !important;
}

.bg-danger {
  background-color: var(--danger-color) !important;
}

.bg-muted {
  background-color: var(--muted-background-color) !important;
}

.bg-green {
  background-color: var(--color-green) !important;
}

.bg-yellow {
  background-color: var(--btn-yellow) !important;
}

.bg-orange {
  background-color: var(--btn-orange) !important;
}

.bg-red {
  background-color: var(--btn-red) !important;
}

.bg-purple {
  background-color: var(--btn-purple) !important;
}

.bg-black {
  background-color: var(--color-black-lighter) !important;
}

.bg-blue {
  background-color: var(--color-blue) !important;
}

.bg-gray-lightest {
  background-color: var(--color-gray-lightest) !important;
}

.bg-gray-lighter {
  background-color: var(--color-gray-lighter) !important;
}

.bg-gray {
  background-color: var(--color-gray) !important;
}

.bg-gray-darker {
  background-color: var(--color-gray-darker) !important;
}

.bg-gray-darkest {
  background-color: var(--color-gray-darkest) !important;
}

.bg-white {
  background-color: var(--color-white) !important;
}

// legacy colors, do not use
.bg-lightpink {
  background-color: lightpink !important;
}

.bg-lightblue {
  background-color: lightblue !important;
}

.bg-lightyellow {
  background-color: lightyellow !important;
}

.bg-indianred {
  background-color: indianred !important;
}
// end of legacy colors

.bg-hover-primary:hover {
  background-color: var(--primary-color) !important;
}

.bg-hover-info:hover {
  background-color: var(--info-color) !important;
}

.bg-hover-success:hover {
  background-color: var(--success-color) !important;
}

.bg-hover-warning:hover {
  background-color: var(--warning-color) !important;
}

.bg-hover-gray-lighter:hover {
  background-color: var(--color-gray-lighter) !important;
}

.bg-hover-danger:hover {
  background-color: var(--danger-color) !important;
}

.bg-hover-white:hover {
  background-color: var(--color-white) !important;
}

.text-color {
  color: var(--text-color) !important;
}

.text-muted {
  color: var(--muted-color);
}

.text-primary,
.text-primary:hover {
  color: var(--primary-color) !important;
}

.text-info,
.text-info:hover {
  color: var(--info-color) !important;
}

.text-success,
.text-success:hover {
  color: var(--success-color) !important;
}

.text-warning,
.text-warning:hover {
  color: var(--warning-color) !important;
}

.text-danger,
.text-danger:hover {
  color: var(--danger-color) !important;
}

.text-link {
  color: var(--link-color) !important;
}

.text-link:hover {
  text-decoration: underline;
}

.text-link:focus {
  outline: var(--accessibility-outline);
  outline-offset: var(--accessibility-outline-offset);
}

.text-black-lightest {
  color: var(--color-black-lightest) !important;
}

.text-black-lighter {
  color: var(--color-black-lighter) !important;
}

.text-black {
  color: var(--color-black) !important;
}

.text-gray-lightest {
  color: var(--color-gray-lightest) !important;
}

.text-gray-lighter {
  color: var(--color-gray-lighter) !important;
}

.text-gray {
  color: var(--color-gray) !important;
}

.text-gray-darker {
  color: var(--color-gray-darker) !important;
}

.text-gray-darkest {
  color: var(--color-gray-darkest) !important;
}

.text-white {
  color: var(--color-white) !important;
}

.text-blue {
  color: var(--color-blue) !important;
}

.text-yellow {
  color: var(--btn-yellow) !important;
}

.text-green {
  color: var(--btn-green) !important;
}

.text-orange {
  color: var(--btn-orange) !important;
}

.text-red {
  color: var(--btn-red) !important;
}

.text-purple {
  color: var(--btn-purple) !important;
}

// these 4 are legacy and will be removed once all implems have been migrated
.color-info {
  color: var(--info-color) !important;
}

.color-success {
  color: var(--success-color) !important;
}

.color-warning {
  color: var(--warning-color) !important;
}

.color-danger {
  color: var(--danger-color) !important;
}

.text-hover-primary:hover {
  color: var(--primary-color) !important;
}

.text-hover-info:hover {
  color: var(--info-color) !important;
}

.text-hover-success:hover {
  color: var(--success-color) !important;
}

.text-hover-warning:hover {
  color: var(--warning-color) !important;
}

.text-hover-danger:hover {
  color: var(--danger-color) !important;
}

.text-hover-white:hover {
  color: var(--color-white) !important;
}

.border-left-primary {
  border-left-color: var(--primary-color) !important;
}

.border-left-success {
  border-left-color: var(--success-color) !important;
}

.border-left-info {
  border-left-color: var(--info-color) !important;
}

.border-left-warning {
  border-left-color: var(--warning-color) !important;
}

.border-left-danger {
  border-left-color: var(--danger-color) !important;
}

.border-left-green {
  border-left-color: var(--color-green) !important;
}

.border-left-gray {
  border-left-color: var(--color-gray) !important;
}

.border-left-yellow {
  border-left-color: var(--btn-yellow) !important;
}

.border-left-orange {
  border-left-color: var(--btn-orange) !important;
}

.border-left-red {
  border-left-color: var(--btn-red) !important;
}

.border-left-purple {
  border-left-color: var(--btn-purple) !important;
}

.border-left-blue {
  border-left-color: var(--color-blue) !important;
}

.border-left-white {
  border-left-color: var(--color-white) !important;
}

.border-primary {
  border-color: var(--primary-color) !important;
}

.border-success {
  border-color: var(--success-color) !important;
}

.border-info {
  border-color: var(--info-color) !important;
}

.border-warning {
  border-color: var(--warning-color) !important;
}

.border-danger {
  border-color: var(--danger-color) !important;
}

.border-green {
  border-color: var(--color-green) !important;
}

.border-yellow {
  border-color: var(--btn-yellow) !important;
}

.border-orange {
  border-color: var(--btn-orange) !important;
}

.border-red {
  border-color: var(--btn-red) !important;
}

.border-purple {
  border-color: var(--btn-purple) !important;
}

.border-blue {
  border-color: var(--color-blue) !important;
}

.border-white {
  border-color: var(--color-white) !important;
}

.border-gray-lighter {
  border-color: var(--color-gray-lighter) !important;
}

.border-gray {
  border-color: var(--color-gray) !important;
}

.border-gray-darker {
  border-color: var(--color-gray-darkest) !important;
}

.border-0 {
  border-width: 0px !important;
}

.border-1px {
  border-width: 1px !important;
}

.border-2px {
  border-width: 2px !important;
}

.border-3px {
  border-width: 3px !important;
}

.outline {
  outline-style: solid !important;
}

.outline-dotted {
  outline-style: dotted !important;
}

.outline-dashed {
  outline-style: dashed !important;
}

.outline-primary {
  outline-color: var(--primary-color) !important;
}

.outline-danger {
  outline-color: var(--danger-color) !important;
}

.outline-warning {
  outline-color: var(--warning-color) !important;
}

.outline-success {
  outline-color: var(--success-color) !important;
}

.outline-info {
  outline-color: var(--info-color) !important;
}

.outline-0 {
  outline-width: 0px !important;
}

.outline-1 {
  outline-width: 1px !important;
}

.outline-2 {
  outline-width: 2px !important;
}

.outline-4 {
  outline-width: 4px !important;
}

.outline-offset-0 {
  outline-offset: 0px !important;
}

.outline-offset-1 {
  outline-offset: 1px !important;
}

.outline-offset-2 {
  outline-offset: 2px !important;
}

.outline-offset-4 {
  outline-offset: 4px !important;
}

.b-0 {
  border: 0 !important;
}

.bb-0 {
  border-bottom: 0 !important;
}

.bt-0 {
  border-top: 0 !important;
}

.br-0 {
  border-radius: 0 !important;
}

.bblr-theme {
  border-bottom-left-radius: var(--border-radius-base) !important;
}

.bbrr-theme {
  border-bottom-right-radius: var(--border-radius-base) !important;
}

.btlr-theme {
  border-top-left-radius: var(--border-radius-base) !important;
}

.btrr-theme {
  border-top-right-radius: var(--border-radius-base) !important;
}

.btlr-0 {
  border-top-left-radius: 0 !important;
}

.btrr-0 {
  border-top-right-radius: 0 !important;
}

.br-theme {
  border-radius: var(--border-radius-base) !important;
}

.btw-0 {
  border-top-width: 0 !important;
}

.btw-1 {
  border-top-width: 0.25rem !important;
}

.btw-2 {
  border-top-width: 0.5rem !important;
}

.btw-2-5 {
  border-top-width: 0.75rem !important;
}

.btw-3 {
  border-top-width: 1rem !important;
}

.btw-4 {
  border-top-width: 1.5rem !important;
}

.btw-5 {
  border-top-width: 3rem !important;
}

.bbw-0 {
  border-bottom-width: 0 !important;
}

.bbw-1 {
  border-bottom-width: 0.25rem !important;
}

.bbw-2 {
  border-bottom-width: 0.5rem !important;
}

.bbw-2-5 {
  border-bottom-width: 0.75rem !important;
}

.bbw-3 {
  border-bottom-width: 1rem !important;
}

.bbw-4 {
  border-bottom-width: 1.5rem !important;
}

.bbw-5 {
  border-bottom-width: 3rem !important;
}

.brw-0 {
  border-right-width: 0 !important;
}

.brw-1 {
  border-right-width: 0.25rem !important;
}

.brw-2 {
  border-right-width: 0.5rem !important;
}

.brw-2-5 {
  border-right-width: 0.75rem !important;
}

.brw-3 {
  border-right-width: 1rem !important;
}

.brw-4 {
  border-right-width: 1.5rem !important;
}

.brw-5 {
  border-right-width: 3rem !important;
}

.blw-0 {
  border-left-width: 0 !important;
}

.blw-1 {
  border-left-width: 0.25rem !important;
}

.blw-2 {
  border-left-width: 0.5rem !important;
}

.blw-2-5 {
  border-left-width: 0.75rem !important;
}

.blw-3 {
  border-left-width: 1rem !important;
}

.blw-4 {
  border-left-width: 1.5rem !important;
}

.blw-5 {
  border-left-width: 3rem !important;
}

.bt-dashed {
  border-top-style: dashed;
}

.border-solid {
  border-style: solid;
}

.bt-solid {
  border-top-style: solid !important;
}

.bb-solid {
  border-bottom-style: solid !important;
}

.br-solid {
  border-right-style: solid !important;
}

.bl-solid {
  border-left-style: solid !important;
}

.pd-theme {
  padding: var(--block-spacing) !important;
}

.pdt-theme {
  padding-top: var(--block-spacing) !important;
}

.pdb-theme {
  padding-bottom: var(--block-spacing) !important;
}

.pdl-theme {
  padding-left: var(--block-spacing) !important;
}

.pdr-theme {
  padding-right: var(--block-spacing) !important;
}

.pd-0 {
  padding: 0 !important;
}

.pd-5px {
  padding: 5px !important;
}

.pdt-0 {
  padding-top: 0 !important;
}

.pdt-2 {
  padding-top: 0.5rem !important;
}

.pdt-3 {
  padding-top: 1rem !important;
}

.pdt-4 {
  padding-top: 1.5rem !important;
}

.pdt-5px {
  padding-top: 5px !important;
}

.pdl-0 {
  padding-left: 0 !important;
}

.pdl-5px {
  padding-left: 5px !important;
}

.pdl-2 {
  padding-left: 0.5rem !important;
}

.pdl-3 {
  padding-left: 1rem !important;
}

.pdl-4 {
  padding-left: 1.5rem !important;
}

.pdr-0 {
  padding-right: 0 !important;
}

.pdr-5px {
  padding-right: 5px !important;
}

.pdr-2 {
  padding-right: 0.5rem !important;
}

.pdr-3 {
  padding-right: 1rem !important;
}

.pdb-2 {
  padding-bottom: 0.5rem !important;
}

.pdb-3 {
  padding-bottom: 1rem !important;
}

.pdb-4 {
  padding-bottom: 1.5rem !important;
}

.pdb-5px {
  padding-bottom: 5px !important;
}

.m-auto {
  margin: 0 auto;
}

.m-theme {
  margin: var(--block-spacing) !important;
}

.mt-theme {
  margin-top: var(--block-spacing) !important;
}

.mb-theme {
  margin-bottom: var(--block-spacing) !important;
}

.ml-theme {
  margin-left: var(--block-spacing) !important;
}

.mr-theme {
  margin-right: var(--block-spacing) !important;
}

.m-0 {
  margin: 0 !important;
}

.m-5px {
  margin: 5px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-5px {
  margin-top: 5px !important;
}

.mt-10px {
  margin-top: 10px !important;
}

.mt-1 {
  margin-top: 10px !important;
}

// to remove once implementation-floa-merchant is merged
.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-5px {
  margin-right: 5px !important;
}

.mr-10px {
  margin-right: 10px !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5px {
  margin-bottom: 5px !important;
}

.mb-10px {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-5px {
  margin-left: 5px !important;
}

.ml-10px {
  margin-left: 10px !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 2rem !important;
}

.margin-vertical-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.text-strike {
  text-decoration: line-through !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

// Generate all width (w-X) from 1% to 100%
@for $i from 1 through 100 {
  .w-#{$i} {
    width: percentage(math.div($i, 100)) !important;
  }
}

.w-33 {
  width: 33.333% !important;
}

.w-66 {
  width: 66.666% !important;
}

.h-theme {
  height: 42px !important;
}

.h-100 {
  height: 100% !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.font-theme {
  font-size: var(--font-size-base) !important;
}

// the base is set to 10px in the html tag css declaration
// meaning that 1.2rem = 12px, 1.4rem = 14px, etc.
.font-1 {
  font-size: 1rem !important;
}

.font-1-1 {
  font-size: 1.1rem !important;
}

.font-1-2 {
  font-size: 1.2rem !important;
}

.font-1-3 {
  font-size: 1.3rem !important;
}

.font-1-4 {
  font-size: 1.4rem !important;
}

.font-1-5 {
  font-size: 1.5rem !important;
}

.font-2 {
  font-size: 2rem !important;
}

.font-2-5 {
  font-size: 2.5rem !important;
}

.font-3 {
  font-size: 3rem !important;
}

.font-4 {
  font-size: 4rem !important;
}

.font-5 {
  font-size: 5rem !important;
}

.font-style-italic {
  font-style: italic;
}

.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.align-top {
  vertical-align: top !important;
}

.leading-none {
  line-height: 1 !important;
}

.leading-0 {
  line-height: 0 !important;
}

.leading-1 {
  line-height: 1rem !important;
}

.leading-1-5 {
  line-height: 1.5rem !important;
}

.leading-2 {
  line-height: 2rem !important;
}

.leading-2-4 {
  // this one fits the accessibile 24px min height 
  line-height: 2.4rem !important;
}

.leading-3 {
  line-height: 3rem !important;
}

.leading-3-5 {
  line-height: 3.5rem !important;
}

.leading-3-75 {
  line-height: 3.75rem !important;
}

.leading-4 {
  line-height: 4rem !important;
}

.leading-5 {
  line-height: 5rem !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.float-clear {
  clear: both !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.white-space-pre-line {
  white-space: pre-line !important;
}

.white-space-normal {
  white-space: normal !important;
}

.underline {
  text-decoration: underline;
}

.grid {
  display: grid;
}

.grid-gap-5px {
  grid-gap: 5px;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-auto-fit-250px {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.inline-flex {
  display: inline-flex;
}

.inline-flex-center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

// shortcut because its very common
.grow {
  flex-grow: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// shortcut because its very common
.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.2 !important;
}

.opacity-2 {
  opacity: 0.4 !important;
}

.opacity-3 {
  opacity: 0.6 !important;
}

.opacity-4 {
  opacity: 0.8 !important;
}

.opacity-5 {
  opacity: 1 !important;
}

.rotate-0 {
  transform: rotate(0deg) !important
}

.rotate-90 {
  transform: rotate(90deg) !important
}

.columns-2 {
  columns: 2;
}

.columns-3 {
  columns: 3;
}

.columns-4 {
  columns: 4;
}

.uppercase {
  text-transform: uppercase;
}

.invisible {
  visibility: hidden;
}

.outline-accessible:focus {
  outline: var(--accessibility-outline);
  outline-offset: var(--accessibility-outline-offset);
}

.outline-accessible-inset:focus {
  outline: var(--accessibility-outline);
  outline-offset: var(--accessibility-outline-offset-inset);
}

.break-all {
  word-break: break-all;
}

.break-words {
  word-break: break-word;
}

// In tailwind they use ":" to prefix variants
// however this is in fact an illegal character in CSS,
// so we use instead "_".
@media (max-width: $screen-md) {
  .md_grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (max-width: $screen-sm) {
  .sm_d-none {
    display: none !important;
  }

  .sm_grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm_columns-2 {
    columns: 2;
  }
}

@media (max-width: $screen-xs) {
  .xs_d-none {
    display: none !important;
  }

  .xs_grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .xs_grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xs_columns-1 {
    columns: 1;
  }
}