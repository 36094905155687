@use "sass:math";

// Scales for responsive SVG containers
$ct-scales: (
  (1),
  math.div(15, 16),
  math.div(8, 9),
  math.div(5, 6),
  (4 * 0.2),
  (3 * 0.25),
  math.div(2, 3),
  (5 * 0.125),
  math.div(1, 1.618),
  (3 * 0.2),
  math.div(9, 16),
  math.div(8, 15),
  (1 * 0.5),
  (2 * 0.2),
  (3 * 0.125),
  math.div(1, 3),
  (1 * 0.25)) !default;
$ct-scales-names: (
  ct-square,
  ct-minor-second,
  ct-major-second,
  ct-minor-third,
  ct-major-third,
  ct-perfect-fifth,
  ct-minor-sixth,
  ct-golden-section,
  ct-major-sixth,
  ct-minor-seventh,
  ct-major-seventh,
  ct-octave,
  ct-major-tenth,
  ct-major-eleventh,
  ct-major-twelfth,
  ct-double-octave) !default;

// Class names to be used when generating CSS
$ct-class-chart: ct-chart !default;
$ct-class-chart-line: ct-chart-line !default;
$ct-class-chart-bar: ct-chart-bar !default;
$ct-class-horizontal-bars: ct-horizontal-bars !default;
$ct-class-chart-pie: ct-chart-pie !default;
$ct-class-chart-donut: ct-chart-donut !default;
$ct-class-label: ct-label !default;
$ct-class-series: ct-series !default;
$ct-class-line: ct-line !default;
$ct-class-point: ct-point !default;
$ct-class-area: ct-area !default;
$ct-class-bar: ct-bar !default;
$ct-class-slice-pie: ct-slice-pie !default;
$ct-class-slice-donut: ct-slice-donut !default;
$ct-class-grid: ct-grid !default;
$ct-class-vertical: ct-vertical !default;
$ct-class-horizontal: ct-horizontal !default;
$ct-class-start: ct-start !default;
$ct-class-end: ct-end !default;

// Container ratio
$ct-container-ratio: math.div(1, 1.618) !default;

// Text styles for labels
$ct-text-color: rgba(0, 0, 0, 0.4) !default;
$ct-text-size: 1.3rem !default;
$ct-text-align: flex-start !default;
$ct-text-justify: flex-start !default;
$ct-text-line-height: 1;

// Grid styles
$ct-grid-color: rgba(0, 0, 0, 0.2) !default;
$ct-grid-dasharray: 2px !default;
$ct-grid-width: 1px !default;

// Line chart properties
$ct-line-width: 3px !default;
$ct-line-dasharray: false !default;
$ct-point-size: 8px !default;
// Line chart point, can be either round or square
$ct-point-shape: round !default;
// Area fill transparency between 0 and 1
$ct-area-opacity: 0.8 !default;

// Bar chart bar width
$ct-bar-width: 10px !default;

// Donut width (If donut width is to big it can cause issues where the shape gets distorted)
$ct-donut-width: 60px !default;

// If set to true it will include the default classes and generate CSS output. If you're planning to use the mixins you
// should set this property to false
$ct-include-classes: true !default;

// If this is set to true the CSS will contain colored series. You can extend or change the color with the
// properties below
$ct-include-colored-series: $ct-include-classes !default;

// If set to true this will include all responsive container variations using the scales defined at the top of the script
$ct-include-alternative-responsive-containers: $ct-include-classes !default;

// Series names and colors. This can be extended or customized as desired. Just add more series and colors.
$ct-series-names: (
  a,
  b,
  c,
  d,
  e,
  f,
  g,
  h,
  i,
  j,
  k,
  l,
  m,
  n,
  o) !default;
$ct-series-colors: (
  #00c0c0,
  #59922b,
  #b2c326,
  #f4c63d,
  orange,
  #d17905,
  #f05b4f,
  #d70206,
  #a748ca,
  #6b0392,
  #0544d3,
  #453d3f,
  #00c0c0,
  #59922b,
  #b2c326,
  #f4c63d,
  orange,
  #d17905,
  #f05b4f,
  #d70206,
  #a748ca,
  #6b0392,
  #0544d3,
  #453d3f,
  #00c0c0,
  #59922b,
  #b2c326,
  #f4c63d,
  orange,
  #d17905,
  #f05b4f,
  #d70206,
  #a748ca,
  #6b0392,
  #0544d3,
  #453d3f,
  #00c0c0,
  #59922b,
  #b2c326,
  #f4c63d,
  orange,
  #d17905,
  #f05b4f,
  #d70206,
  #a748ca,
  #6b0392,
  #0544d3,
  #453d3f,
  #00c0c0,
  #59922b,
  #b2c326,
  #f4c63d,
  orange,
  #d17905,
  #f05b4f,
  #d70206,
  #a748ca,
  #6b0392,
  #0544d3,
  #453d3f) !default;

@mixin ct-responsive-svg-container($width: 100%, $ratio: $ct-container-ratio) {
  display: block;
  position: relative;
  width: $width;

  &:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: $ratio * 100%;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  >svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@mixin ct-align-justify($ct-text-align: $ct-text-align, $ct-text-justify: $ct-text-justify) {
  align-items: $ct-text-align;
  justify-content: $ct-text-justify;

  // Fallback to text-align for non-flex browsers
  @if ($ct-text-justify =="flex-start") {
    text-align: left;
  }

  @else if ($ct-text-justify =="flex-end") {
    text-align: right;
  }

  @else {
    text-align: center;
  }
}

@mixin ct-flex() {
  // Fallback to block
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin ct-chart-label($ct-text-color: $ct-text-color, $ct-text-size: $ct-text-size, $ct-text-line-height: $ct-text-line-height) {
  fill: $ct-text-color;
  color: $ct-text-color;
  font-size: $ct-text-size;
  line-height: $ct-text-line-height;
}

@mixin ct-chart-grid($ct-grid-color: $ct-grid-color, $ct-grid-width: $ct-grid-width, $ct-grid-dasharray: $ct-grid-dasharray) {
  stroke: $ct-grid-color;
  stroke-width: $ct-grid-width;

  @if ($ct-grid-dasharray) {
    stroke-dasharray: $ct-grid-dasharray;
  }
}

@mixin ct-chart-point($ct-point-size: $ct-point-size, $ct-point-shape: $ct-point-shape) {
  stroke-width: $ct-point-size;
  stroke-linecap: $ct-point-shape;
}

@mixin ct-chart-line($ct-line-width: $ct-line-width, $ct-line-dasharray: $ct-line-dasharray) {
  fill: none;
  stroke-width: $ct-line-width;

  @if ($ct-line-dasharray) {
    stroke-dasharray: $ct-line-dasharray;
  }
}

@mixin ct-chart-area($ct-area-opacity: $ct-area-opacity) {
  stroke: none;
  fill-opacity: $ct-area-opacity;
}

@mixin ct-chart-bar($ct-bar-width: $ct-bar-width) {
  fill: none;
  stroke-width: $ct-bar-width;
}

@mixin ct-chart-donut($ct-donut-width: $ct-donut-width) {
  fill: none;
  stroke-width: $ct-donut-width;
}

@mixin ct-chart-series-color($color) {

  .#{$ct-class-point},
  .#{$ct-class-line},
  .#{$ct-class-bar},
  .#{$ct-class-slice-donut} {
    stroke: $color;
  }

  .#{$ct-class-slice-pie},
  .#{$ct-class-area} {
    fill: $color;
  }
}

@mixin ct-chart($ct-container-ratio: $ct-container-ratio,
  $ct-text-color: $ct-text-color,
  $ct-text-size: $ct-text-size,
  $ct-grid-color: $ct-grid-color,
  $ct-grid-width: $ct-grid-width,
  $ct-grid-dasharray: $ct-grid-dasharray,
  $ct-point-size: $ct-point-size,
  $ct-point-shape: $ct-point-shape,
  $ct-line-width: $ct-line-width,
  $ct-bar-width: $ct-bar-width,
  $ct-donut-width: $ct-donut-width,
  $ct-series-names: $ct-series-names,
  $ct-series-colors: $ct-series-colors) {
  .#{$ct-class-label} {
    @include ct-chart-label($ct-text-color, $ct-text-size
  );
}

.#{$ct-class-chart-line} .#{$ct-class-label},
.#{$ct-class-chart-bar} .#{$ct-class-label} {
  @include ct-flex();
}

.#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-start} {
  @include ct-align-justify(flex-end, flex-start);
  // Fallback for browsers that don't support foreignObjects
  text-anchor: start;
}

.#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-end} {
  @include ct-align-justify(flex-start, flex-start);
  // Fallback for browsers that don't support foreignObjects
  text-anchor: start;
}

.#{$ct-class-label}.#{$ct-class-vertical}.#{$ct-class-start} {
  @include ct-align-justify(flex-end, flex-end);
  // Fallback for browsers that don't support foreignObjects
  text-anchor: end;
}

.#{$ct-class-label}.#{$ct-class-vertical}.#{$ct-class-end} {
  @include ct-align-justify(flex-end, flex-start);
  // Fallback for browsers that don't support foreignObjects
  text-anchor: start;
}

.#{$ct-class-chart-bar} .#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-start} {
  @include ct-align-justify(flex-end, center);
  // Fallback for browsers that don't support foreignObjects
  text-anchor: start;
}

.#{$ct-class-chart-bar} .#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-end} {
  @include ct-align-justify(flex-start, center);
  // Fallback for browsers that don't support foreignObjects
  text-anchor: start;
}

.#{$ct-class-chart-bar}.#{$ct-class-horizontal-bars} .#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-start} {
  @include ct-align-justify(flex-end, flex-start);
  // Fallback for browsers that don't support foreignObjects
  text-anchor: start;
}

.#{$ct-class-chart-bar}.#{$ct-class-horizontal-bars} .#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-end} {
  @include ct-align-justify(flex-start, flex-start);
  // Fallback for browsers that don't support foreignObjects
  text-anchor: start;
}

.#{$ct-class-chart-bar}.#{$ct-class-horizontal-bars} .#{$ct-class-label}.#{$ct-class-vertical}.#{$ct-class-start} {
  //@include ct-chart-label($ct-text-color, $ct-text-size, center, $ct-vertical-text-justify);
  @include ct-align-justify(center, flex-end);
  // Fallback for browsers that don't support foreignObjects
  text-anchor: end;
}

.#{$ct-class-chart-bar}.#{$ct-class-horizontal-bars} .#{$ct-class-label}.#{$ct-class-vertical}.#{$ct-class-end} {
  @include ct-align-justify(center, flex-start);
  // Fallback for browsers that don't support foreignObjects
  text-anchor: end;
}

.#{$ct-class-grid} {
  @include ct-chart-grid($ct-grid-color, $ct-grid-width, $ct-grid-dasharray);
}

.#{$ct-class-point} {
  @include ct-chart-point($ct-point-size, $ct-point-shape);
}

.#{$ct-class-line} {
  @include ct-chart-line($ct-line-width);
}

.#{$ct-class-area} {
  @include ct-chart-area();
}

.#{$ct-class-bar} {
  @include ct-chart-bar($ct-bar-width);
}

.#{$ct-class-slice-donut} {
  @include ct-chart-donut($ct-donut-width);
}

@if $ct-include-colored-series {
  @for $i from 0 to length($ct-series-names) {
    .#{$ct-class-series}-#{nth($ct-series-names, $i + 1)} {
      $color: nth($ct-series-colors, $i + 1);

      @include ct-chart-series-color($color);
    }
  }
}
}

@if $ct-include-classes {
  @include ct-chart();

  @if $ct-include-alternative-responsive-containers {
    @for $i from 0 to length($ct-scales-names) {
      .#{nth($ct-scales-names, $i + 1)} {
        @include ct-responsive-svg-container($ratio: nth($ct-scales, $i + 1));
      }
    }
  }
}

.ct-blue {
  stroke: var(--color-blue) !important;
}

.ct-green {
  stroke: var(--color-green) !important;
}

.ct-orange {
  stroke: var(--color-orange) !important;
}

.ct-red {
  stroke: var(--color-red) !important;
}

.ct-legend {
  position: relative;
  z-index: 10;

  li {
    position: relative;
    padding-left: 23px;
    margin-bottom: 0px;
    margin-right: 10px;
    cursor: pointer;
    display: inline-block;
  }

  li:before {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    content: "";
    border: 3px solid transparent;
    border-radius: 2px;
    top: 2px;
  }

  li.inactive:before {
    background: transparent;
  }

  &.ct-legend-inside {
    position: absolute;
    top: 0;
    right: 0;
  }

  @for $i from 0 to length($ct-series-colors) {
    .ct-series-#{$i}:before {
      background-color: nth($ct-series-colors, $i + 1);
      border-color: nth($ct-series-colors, $i + 1);
    }
  }

  ul {
    margin-bottom: 0px;
  }
}

.chartist-card {
  .ct-label.ct-horizontal.ct-end {
    justify-content: flex-end;
    position: relative;
    text-align: right;
    transform-origin: 100% 0;
    transform: translate(-100%) rotate(-45deg);
    white-space: nowrap;
  }

  &[data-chart-type="bar"] {
    .ct-label.ct-horizontal.ct-end {
      transform: translate(-50%) rotate(-45deg);
    }
  }

  &[data-chart-type="pie"] {
    .ct-chart {
      margin-top: 20px;
    }
  }
}