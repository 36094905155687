.person-relation-chart,
.person-hierarchy-chart,
.workflow-chart {
  display: none;
  width: 100%;

  &[data-show="true"] {
    display: block;
  }

  &[data-is-loading="true"] {
    opacity: 0;
  }

  &[data-large-view="true"] {
    width: 100%;
    height: 100vh;
  }
}

.person-relation-chart,
.person-hierarchy-chart {
  height: 40vh;
}

.workflow-chart {
  min-height: 68vh;
}

.chart-context-menu {
  background: var(--color-white);
  border: 1px solid var(--muted-border-color);
  border-radius: var(--border-radius-base);
  opacity: 0;
  position: absolute;
  visibility: hidden;

  &[data-show="true"] {
    opacity: 1;
    visibility: visible;
  }

  .chart-context-menu-options {
    cursor: pointer;
    padding: var(--nav-controls-link-padding);

    &:hover {
      background-color: var(--color-gray-lighter);
    }
  }
}

.go-js-component {
  height: 100%;
  min-height: 68vh;
  width: 100%;
}

.go-js-person {
  height: 40vh;
  width: 100%;
}

.go-js-person-large {
  height: 100vh;
}