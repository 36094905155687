// The easiest way to test this css is to create notifications.
// For instance in /src/index.js you can instantiate them
// by copying the code block that says that a new app version is available
// and pasting it at the end of the file so they are fired at initial load.

// Don't set the height on notifications-wrapper or notification
// because the lib uses it to perform the close animation by setting it to 0

.notifications-wrapper {
  [class^="notifications-"] {
    border: 0px !important;
    color: rgb(255, 255, 255) !important;
    display: flex !important;
    flex-direction: column;
    font-family: var(--font-family) !important;
    font-size: 1.4rem !important;
    font-weight: 400 !important;
    margin: 0px auto !important;
    padding: var(--card-padding-axial) var(--card-padding-lateral) !important;
    position: fixed !important;
    width: 100% !important;
    z-index: 9998 !important;
  }

  .notifications-tr {
    align-items: flex-end;
    max-width: 50%;
  }

  .notifications-tc {
    align-items: center;
    inset: 0 auto auto auto !important;
  }

  .notifications-br {
    align-items: flex-end;
    max-width: calc(min(50%, 512px));
  }

  .notifications-bc {
    align-items: center;
    inset: auto auto 0 auto !important;
  }

  .notification-title {
    color: white !important;
    font-size: 3rem !important;
  }

  .notification-message {
    color: white !important;
  }

  .notification-dismiss {
    background-color: unset !important;
    color: white !important;
    font-family: inherit !important;
    font-size: 2.2rem !important;
    height: 25px !important;
    padding: 6px !important;
    right: 10px !important;
    top: -4px !important;
  }

  .notification {
    border-radius: var(--card-border-radius) !important;
    border-top: 0 !important;
    box-shadow: none !important;
    font-size: 1.4rem !important;
    height: auto !important;
    margin: 5px 0 !important;
    max-width: 512px !important;
    padding: var(--card-padding-axial) var(--card-padding-lateral) !important;
    transition: all 0.5s ease-in-out !important;

    &.notification-success {
      background-color: var(--success-color) !important;

      .notification-action-button {
        background-color: var(--color-states-green) !important;
        color: var(--color-white) !important;
      }
    }

    &.notification-error {
      background-color: var(--danger-color) !important;

      .notification-action-button {
        background-color: var(--color-states-red) !important;
        color: var(--color-white) !important;
      }
    }

    &.notification-warning {
      background-color: var(--warning-color) !important;

      .notification-action-button {
        background-color: var(--color-states-orange) !important;
        color: var(--color-white) !important;
      }
    }

    &.notification-info {
      background-color: var(--info-color) !important;

      .notification-action-button {
        background-color: var(--color-states-blue) !important;
        color: var(--color-white) !important;
      }
    }
  }

  .notification-action-button {
    background: white !important;
    border: 0 !important;
    border-radius: var(--btn-border-radius) !important;
    font-weight: normal !important;
    padding: var(--btn-lg-padding) !important;
  }
}

@media (max-width: $screen-xs) {
  .notifications-wrapper {

    .notifications-tr,
    .notifications-br {
      max-width: unset;
    }
  }
}

.notice {
  background: var(--muted-background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 34px;
  padding: 4px 12px;

  &.notice-primary {
    background: var(--primary-background-color);
  }

  &.notice-info {
    background: var(--info-background-color);
  }

  &.notice-success {
    background: var(--success-background-color);
  }

  &.notice-warning {
    background: var(--warning-background-color);
  }

  &.notice-danger {
    background: var(--danger-background-color);
  }
}