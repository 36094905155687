.documentation-page,
.api-documentation-page,
.note-text,
.form-input-markdown,
.md-content,
.openapi {

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  a,
  li {
    font-family: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 600;
    margin: var(--block-spacing) 0;
    scroll-margin-top: calc(var(--app-controls-height) + var(--block-spacing));
  }

  h1 {
    border-bottom: 1px solid var(--color-gray-lighter);
    font-size: 3.2rem !important;
    line-height: 40px;
    margin-top: 0;
    padding-bottom: var(--block-spacing);
  }

  h2 {
    border-bottom: 1px solid var(--color-gray-lighter);
    font-size: 2.4rem !important;
    line-height: 30px;
    padding-bottom: var(--block-spacing);
  }

  h3 {
    font-size: 2rem !important;
    line-height: 26px;
  }

  h4 {
    font-size: 1.6rem !important;
    line-height: 20px;
  }

  h5 {
    font-size: 1.4rem !important;
    line-height: 17.5px;
  }

  h6 {
    font-size: 1.36rem !important;
    line-height: 17px;
  }

  a {
    color: var(--info-color);
  }

  img {
    height: 100%;
    max-width: 100%;
  }

  ul {
    padding-left: 32px;
  }

  li {
    padding: 2px 0;
  }

  th,
  td {
    border-bottom: 1px solid var(--color-gray-bg);
  }

  pre {
    background: #1F1F1F;
    border: 0;
    border-radius: var(--border-radius-base);
    color: #9CDCFE;
    filter: saturate(1.1) brightness(1.03) contrast(1.04);
    overflow: unset;
  }

  blockquote {
    background-color: #ecffed;
    border-left: 0;
    border: 1px solid var(--success-color);
    border-radius: var(--border-radius-base);
    font-size: var(--font-size-base);
    padding: var(--block-spacing);
  }
}

.documentation-page,
.api-documentation-page,
.openapi {

  h1,
  h2,
  h3,
  h4,
  h5 {
    &[id] {
      cursor: pointer;

      &:hover::after {
        content: " #";
      }
    }
  }

  .card .content {
    margin: 0 auto;
    max-width: 1140px;
  }
}

.openapi {
  // POSTMAN colors (almost)
  // --color-http-method-get: #16BE59;
  // --color-http-method-post: #FFB709;
  // --color-http-method-put: #1280EE;
  // --color-http-method-patch: #9012FE;
  // --color-http-method-delete: #EB2013;
  // --color-http-method-options: #5A5A5A;
  // --color-http-method-head: #212121;

  // SWAGGER colors
  // --color-http-method-get: #61AFFE;
  // --color-http-method-post: #46C289;
  // --color-http-method-put: #FBA030;
  // --color-http-method-patch: #50E3C2;
  // --color-http-method-delete: #F93E3E;
  // --color-http-method-options: #0D5AA7;
  // --color-http-method-head: #9012FE;

  --color-http-method-get: #46c289;
  --color-http-method-post: #fba030;
  --color-http-method-put: #1280ee;
  --color-http-method-patch: #a92bff;
  --color-http-method-delete: #f93e3e;
  --color-http-method-options: #5a5a5a;
  --color-http-method-head: #212121;

  p {
    margin-bottom: 0;
  }

  h4 {
    align-items: center;
    display: flex;

    .openapi-header-section-line {
      border-bottom: 1px solid var(--color-gray-lighter);
      display: inline-block;
      flex-grow: 1;
      height: 1px;
      margin-left: 8px;
    }
  }

  .openapi-info h2 {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  [data-endpoint-deprecated="true"] {
    filter: grayscale(1);
    opacity: 0.55;

    .endpoint-path span {
      text-decoration: line-through;
    }
  }

  .openapi-tags,
  .openapi-servers,
  .openapi-security-schemes,
  .openapi-endpoints,
  .openapi-schemas {
    border-bottom: 1px solid var(--color-gray-lighter);

    &[data-show-tags="true"],
    &[data-show-servers="true"],
    &[data-show-security-schemes="true"],
    &[data-show-endpoints="true"],
    &[data-show-schemas="true"] {
      .openapi-tags-list {
        display: block;
      }

      .openapi-servers-list {
        display: block;
      }

      .openapi-security-schemes-list {
        display: block;
      }

      .openapi-endpoints-list {
        display: block;
      }

      .openapi-schemas-list {
        display: block;
      }

      h3 .icn-chevron-right {
        transform: rotateZ(90deg);
      }
    }

    h3 {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-right: 2px;
    }
  }

  .openapi-tags-list,
  .openapi-servers-list,
  .openapi-security-schemes-list,
  .openapi-endpoints-list,
  .openapi-schemas-list {
    display: none;
  }

  .openapi-endpoint {
    background: var(--color-gray-lightest);
    border-radius: var(--border-radius-base);
    margin: 6px 0;

    &[data-show-endpoint="true"] {
      .icn-chevron-right {
        transform: rotateZ(90deg);
      }
    }

    &[data-http-method="get"] .endpoint-http-method {
      background: var(--color-http-method-get);
    }

    &[data-http-method="post"] .endpoint-http-method {
      background: var(--color-http-method-post);
    }

    &[data-http-method="put"] .endpoint-http-method {
      background: var(--color-http-method-put);
    }

    &[data-http-method="patch"] .endpoint-http-method {
      background: var(--color-http-method-patch);
    }

    &[data-http-method="delete"] .endpoint-http-method {
      background: var(--color-http-method-delete);
    }

    &[data-http-method="options"] .endpoint-http-method {
      background: var(--color-http-method-options);
    }

    &[data-http-method="head"] .endpoint-http-method {
      background: var(--color-http-method-head);
    }
  }

  .openapi-endpoint-title-block {
    align-items: center;
    display: flex;
    padding: 2px;
  }

  .endpoint-http-method {
    border-radius: var(--border-radius-base);
    color: var(--color-white);
    font-weight: bold;
    min-width: 55px;
    padding: 2px 0;
    text-align: center;
    text-transform: uppercase;
  }

  .endpoint-path {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    padding-left: 8px;
  }

  .openapi-schema {
    border-bottom: 1px dotted var(--color-gray-lighter);

    &[data-show-schema="true"] {
      .icn-chevron-right {
        transform: rotateZ(90deg);
      }
    }
  }

  .openapi-schema-name {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 6px 0;
    padding: 2px 2px 2px 0;
  }

  .openapi-schema-content {
    background: var(--color-gray-lightest);
    padding: var(--block-spacing);
  }

  .icn-chevron-right {
    color: var(--color-gray);
    font-size: 1.2rem;
  }

  .openapi-schema-field {
    &[data-show-nested-field] {
      .icn-chevron-right {
        color: var(--text-color);
      }
    }

    &[data-show-nested-field="false"] {
      .openapi-schema-field-inner {
        .icn-chevron-right {
          transform: rotateZ(0deg);
        }
      }

      .openapi-schema-field {
        display: none;
      }
    }

    &[data-field-array="true"],
    &[data-field-object="true"] {
      >.openapi-schema-field-inner .openapi-field-key {
        cursor: pointer;
      }
    }
  }

  .openapi-schema-field-inner,
  .openapi-schema-field-inner-properties,
  .openapi-response-header {
    display: flex;
  }

  .openapi-field-key {
    align-items: center;
    display: inline-flex;
    min-width: 300px;
    padding-right: 4px;
  }

  .openapi-endpoint-response {
    border-bottom: 1px dashed var(--color-gray-lighter);
    padding-bottom: var(--block-spacing);

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  .openapi-endpoint-content {
    overflow-x: auto;
  }

  .openapi-schema-root-array {
    font-style: italic;
    transform: translateX(-4px);
  }

  .openapi-field-line {
    border-bottom: 1px dotted var(--color-gray);
    flex-grow: 1;
    margin-left: var(--block-spacing);
  }
}

@media (max-width: $screen-xs) {
  .openapi {
    .openapi-field-key {
      min-width: 40vw;
    }
  }
}