// media queries don't support css variables
$screen-xxs: 320px;
$screen-xs: 480px;
$screen-xs-sm: 576px;
$screen-sm: 768px;
$screen-sm-1: $screen-sm + 1px;
$screen-md: 992px;
$screen-md-1: $screen-md + 1px;
$screen-lg: 1200px;
$screen-xl: 1440px;
$screen-xxl: 1680px;

// do not use size suffixes: xxs, xs, sm, md, lg
$icns: address-book,
  address-book-light,
  ai-prompt,
  airplay,
  airplay-light,
  align-right,
  anchor,
  anchor-light,
  angle-double-left,
  angle-double-left-light,
  angle-double-right,
  angle-double-right-light,
  archive,
  archive-light,
  arrow-down,
  arrow-down-light,
  arrow-left,
  arrow-left-light,
  arrow-right,
  arrow-right-light,
  arrow-turn-down,
  arrow-turn-up,
  arrow-up,
  arrow-up-light,
  arrows-from-line,
  arrows-to-line,
  at,
  at-light,
  atom,
  atom-light,
  asterisk,
  asterisk-light,
  balance-scale,
  balance-scale-light,
  ban,
  ban-light,
  bandage,
  bandage-light,
  bank,
  bank-light,
  barcode,
  barcode-light,
  bed,
  bed-light,
  bell,
  bell-light,
  bezier-curve,
  bezier-curve-light,
  bicycle,
  bicycle-light,
  binoculars,
  binoculars-light,
  blockquote,
  bolt,
  bolt-light,
  book,
  book-light,
  book-bookmark-light,
  bookmark-light,
  bow-arrow-light,
  brackets-curly,
  brackets-curly-light,
  broom,
  broom-light,
  building,
  building-light,
  bug,
  bug-light,
  bullhorn,
  bullhorn-light,
  bullseye-arrow,
  bullseye-arrow-light,
  calculator,
  calculator-light,
  calendar,
  calendar-light,
  calendar-check,
  calendar-check-light,
  calendar-xmark,
  calendar-xmark-light,
  camera,
  camera-light,
  camera-cctv-light,
  car,
  car-light,
  car-side,
  car-side-light,
  car-electric-side,
  car-electric-side-light,
  cash,
  cash-light,
  cash-register,
  cash-register-light,
  chart-column,
  chart-line,
  chart-line-light,
  chart-network,
  chart-network-light,
  chart-pie,
  chart-pie-light,
  check,
  check-circle,
  check-circle-light,
  checkbox,
  checkbox-checked,
  checkbox-checked-o,
  checkbox-checked-o-light,
  chevron-down,
  chevron-left,
  chevron-right,
  chevron-up,
  circle,
  circle-h-light,
  circle-dot,
  circle-notch,
  circle-up,
  clipboard-light,
  clock,
  clock-light,
  clone,
  cloud,
  cloud-light,
  cloud-download,
  cloud-download-light,
  cloud-upload,
  cloud-upload-light,
  code,
  code-branch,
  code-diff,
  coffee-hot,
  coffee-hot-light,
  collapse,
  comment-dots,
  comment-dots-light,
  compass,
  compass-light,
  copy,
  copy-light,
  couch-light,
  credit-card-front,
  credit-card-front-light,
  csv-file,
  cube,
  cubes,
  database-light,
  dashboard-light,
  debug,
  debug-light,
  desktop,
  desktop-light,
  diacritic,
  doc-file,
  doctor-light,
  dollar-sign,
  dollar-sign-light,
  double-pipe,
  download,
  download-light,
  droplet,
  droplet-light,
  dumbbell,
  dumbbell-light,
  duplicate,
  earth-americas,
  earth-americas-light,
  earth-europe,
  earth-europe-light,
  edit,
  edit-light,
  ellipsis-h,
  ellipsis-v,
  email,
  email-light,
  envelope,
  envelope-light,
  envelopes,
  envelopes-light,
  envira,
  euro-sign,
  euro-sign-light,
  excel-download,
  excel-file,
  exchange,
  exchange-light,
  exclamation-triangle,
  exclamation-triangle-light,
  exclamation-circle,
  exclamation-circle-light,
  expand,
  expand-light,
  external-link,
  external-link-light,
  eye,
  eye-light,
  eye-slash,
  facebook,
  flag,
  flag-ad,
  flag-am,
  flag-at,
  flag-ba,
  flag-be,
  flag-by,
  flag-ch,
  flag-co,
  flag-cy,
  flag-cz,
  flag-de,
  flag-dk,
  flag-ee,
  flag-es,
  flag-fi,
  flag-fr,
  flag-gb,
  flag-gr,
  flag-hr,
  flag-hu,
  flag-ie,
  flag-it,
  flag-li,
  flag-lt,
  flag-lv,
  flag-mc,
  flag-md,
  flag-me,
  flag-mk,
  flag-mt,
  flag-nl,
  flag-no,
  flag-pl,
  flag-pt,
  flag-ro,
  flag-rs,
  flag-se,
  flag-si,
  flag-sk,
  flag-sm,
  flag-ua,
  flag-uk,
  flag-va,
  flag-light,
  fighter-jet,
  fighter-jet-light,
  file,
  file-batch,
  file-audio,
  file-audio-light,
  file-code,
  file-code-light,
  file-invoice-light,
  file-download,
  file-upload,
  file-contract,
  file-contract-light,
  file-text,
  file-text-light,
  file-video,
  file-video-light,
  flask,
  folder,
  folder-light,
  folder-open,
  folder-open-light,
  forklift,
  forklift-light,
  futbol,
  futbol-light,
  gavel,
  gavel-light,
  gear,
  gear-light,
  gears-light,
  gem-light,
  gift,
  gift-light,
  glasses,
  glasses-light,
  google-wallet,
  graduation-cap,
  graduation-cap-light,
  grip-vertical,
  hand,
  hand-light,
  hand-point-up,
  hand-point-up-light,
  handshake,
  handshake-light,
  heart,
  heart-light,
  hourglass-end,
  hourglass-end-light,
  hourglass-half,
  hourglass-half-light,
  hourglass-start,
  hourglass-start-light,
  history,
  history-light,
  hotel,
  hotel-light,
  hospital-light,
  house,
  house-light,
  ice-cream,
  ice-cream-light,
  icons,
  id-card,
  id-card-light,
  image,
  image-light,
  indent-less,
  indent-more,
  industry,
  industry-light,
  info-circle,
  info-circle-light,
  instagram,
  key,
  key-light,
  keyboard,
  keyboard-light,
  laptop,
  laptop-light,
  leaf,
  leaf-light,
  life-ring,
  life-ring-light,
  lightbulb,
  lightbulb-light,
  lightbulb-on,
  lightbulb-on-light,
  list,
  list-numbered,
  list-unordered,
  list-light,
  link,
  link-light,
  linked-in,
  location-arrow-light,
  lock,
  lock-open,
  login,
  login-light,
  logout,
  logout-light,
  magic-wand,
  magic-wand-light,
  map-location,
  map-location-light,
  map-marker,
  map-marker-light,
  message-bot,
  microscope,
  microscope-light,
  minus,
  minus-light,
  minus-square,
  microphone,
  mobile-phone,
  mobile-phone-light,
  money-check,
  money-check-light,
  motorcycle,
  motorcycle-light,
  music,
  music-light,
  network-wired,
  network-wired-light,
  newspaper,
  newspaper-light,
  object-group,
  object-group-light,
  paint-roller-light,
  paperclip,
  paperplane,
  paperplane-light,
  parking,
  paste,
  pen-light,
  pencil,
  pencil-light,
  percent,
  person-running,
  pdf-file,
  pdf-download,
  piggy-light,
  phone,
  phone-light,
  plane-light,
  play,
  play-light,
  play-forward,
  play-forward-light,
  plug,
  plug-light,
  plus,
  plus-light,
  plus-square,
  power,
  power-light,
  powerpoint-file,
  print,
  print-light,
  push-right,
  qr-code,
  qr-code-light,
  qr-code-scan,
  question-mark-circle-light,
  radar,
  radar-light,
  recycle,
  reload,
  reload-light,
  remove-formatting,
  restart,
  restart-light,
  retweet,
  road-light,
  rocket,
  rocket-light,
  save,
  save-light,
  scissors,
  scissors-light,
  screwdriver-wrench,
  screwdriver-wrench-light,
  search,
  search-light,
  search-magic,
  search-minus,
  search-minus-light,
  search-filter,
  servers,
  servers-light,
  share-nodes,
  share-nodes-light,
  shield-light,
  ship,
  ship-light,
  ship-helm,
  shopping-bag-light,
  shopping-cart,
  shopping-cart-light,
  shuffle,
  shuffle-light,
  signs-post,
  signs-post-light,
  sliders,
  sliders-light,
  sort,
  sort-up,
  sort-down,
  split-view,
  spoon,
  spoon-light,
  stack-overflow,
  star,
  star-light,
  star-filled,
  stethoscope,
  stethoscope-light,
  stop,
  stop-sign,
  stopwatch-light,
  street-view,
  street-view-light,
  subscript,
  suitcase,
  suitcase-light,
  suitcase-medical,
  suitcase-medical-light,
  sun,
  sun-light,
  superscript,
  table,
  table-pivot,
  tag,
  tag-light,
  tags,
  tags-light,
  tasks,
  tasks-light,
  text-align-center,
  text-align-left,
  text-align-justify,
  text-align-right,
  text-background-color,
  text-bold,
  text-color,
  text-italic,
  text-strikethrough,
  text-underline,
  thumbtack,
  thumbs-down-light,
  thumbs-up-light,
  ticket,
  ticket-light,
  timer,
  timer-light,
  toggle-off,
  toggle-off-light,
  toggle-on,
  toggle-on-light,
  transpose,
  trash,
  trash-light,
  treasure-chest,
  treasure-chest-light,
  tree,
  tree-light,
  trello,
  triangle-corner-top-right,
  trophy,
  trophy-light,
  truck,
  truck-light,
  tv,
  tv-light,
  undo,
  upload,
  upload-light,
  umbrella-beach,
  umbrella-light,
  unlink-light,
  user,
  user-light,
  user-add,
  user-add-light,
  user-circle,
  user-circle-light,
  user-impersonation,
  user-admin,
  user-superadmin,
  users,
  users-light,
  utensils,
  utensils-light,
  van,
  van-light,
  vault,
  vault-light,
  video,
  video-light,
  wallet,
  wallet-light,
  webpage,
  whatsapp,
  wheelchair,
  wheelchair-light,
  word-file,
  wrench,
  xmark,
  xmark-light,
  zip-file;

:export {
  breakpointXs: $screen-xs;
  breakpointSm: $screen-sm;
  icns: $icns;
}