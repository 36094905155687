.virtual-keyboard {
  --virtual-keyboard-bg-color: #1a1a1a;
  --virtual-keyboard-key-bg-color: #2e2e2e;

  bottom: 0;
  left: 50%;
  position: fixed;
  transform: translate(-50%);
  z-index: 1000000;

  .virtual-keyboard-configs {
    background-color: var(--virtual-keyboard-bg-color);
    border-top-left-radius: var(--border-radius-base);
    border-top-right-radius: var(--border-radius-base);
    color: var(--color-white);
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 500px;
    padding: 1px 5px 0;
    padding-bottom: 0;
  }

  .virtual-keyboard-config {
    background: var(--virtual-keyboard-key-bg-color);
    border-radius: var(--btn-border-radius);
    color: var(--color-white);
    cursor: pointer;
    flex-grow: 1;
    font-size: 1.2rem;
    margin: 4px 2px 0;
    padding: var(--btn-sm-padding);
    text-align: center;

    &[data-active-keyboard="true"] {
      background: var(--color-gray-darkest);
    }
  }

  .simple-keyboard {
    background-color: var(--virtual-keyboard-bg-color);
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 500px;
    padding: 4px;
    position: relative;
    touch-action: manipulation;
    user-select: none;

    .hg-button span {
      pointer-events: none;
    }

    button.hg-button {
      border-width: 0;
      font-size: inherit;
      outline: 0;
    }

    .hg-button {
      display: inline-block;
      flex-grow: 1;
    }

    .hg-row {
      display: flex;
    }

    .hg-row:not(:last-child) {
      margin-bottom: 4px;
    }

    .hg-row .hg-button-container,
    .hg-row .hg-button {
      margin: 0 2px;
    }

    .hg-row .hg-button-container {
      display: flex;
    }

    .hg-button {
      align-items: center;
      background: var(--virtual-keyboard-key-bg-color);
      border-radius: var(--btn-border-radius);
      box-sizing: border-box;
      color: var(--color-white);
      cursor: pointer;
      display: flex;
      height: 40px;
      justify-content: center;
      padding: 5px;
    }

    .hg-button.hg-standardBtn {
      max-width: 40px;
      min-width: 20px;
    }

    .hg-button.hg-activeButton {
      background: var(--color-gray-darkest);
    }

    .hg-theme-default.hg-layout-numeric .hg-button {
      align-items: center;
      display: flex;
      height: 60px;
      justify-content: center;
      width: 33.3%;
    }

    .hg-button.hg-button-numpadadd,
    .hg-button.hg-button-numpadenter {
      height: 85px;
    }

    .hg-button.hg-button-numpad0 {
      width: 105px;
    }

    .hg-button.hg-button-com {
      max-width: 85px;
    }

    .hg-button.hg-standardBtn.hg-button-at {
      max-width: 45px;
    }

    .hg-button.hg-selectedButton {
      background: rgba(5, 25, 70, 0.53);
      color: var(--color-white);
    }

    .hg-candidate-box {
      background: var(--virtual-keyboard-bg-color);
      color: var(--color-white);
      display: inline-flex;
      left: 0;
      padding: 5px;
      padding-bottom: 0;
      position: absolute;
      top: 0;
      transform: translateY(-100%);
      user-select: none;
      width: 100%;
    }

    ul.hg-candidate-box-list {
      display: flex;
      flex: 1;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li.hg-candidate-box-list-item {
      align-items: center;
      background: var(--virtual-keyboard-key-bg-color);
      border-radius: var(--btn-border-radius);
      cursor: pointer;
      display: flex;
      height: 40px;
      justify-content: center;
      margin: 0 2px;
      max-width: 40px;
      min-width: 30px;
    }

    .hg-candidate-box-prev {
      margin: 0 2px;

      &:before {
        content: "◄";
      }
    }

    .hg-candidate-box-next {
      margin-left: 4px;

      &:before {
        content: "►";
      }
    }

    .hg-candidate-box-next,
    .hg-candidate-box-prev {
      align-items: center;
      background: var(--virtual-keyboard-bg-color);
      border-radius: var(--btn-border-radius);
      color: var(--color-white);
      cursor: pointer;
      display: flex;
      justify-content: center;
      opacity: 0.3;
      width: 30px;
    }

    .hg-candidate-box-btn-active {
      opacity: 1;
    }
  }
}

@media (max-width: $screen-xs) {
  .virtual-keyboard {
    width: 100%;
  }
}