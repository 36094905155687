.section-collapse-icon {
  color: var(--card-header-text-color);
  cursor: pointer;
  font-size: 1.8rem;
  position: relative;
  transform: rotateZ(0);
  transition: transform 0.3s ease-out;

  &[data-is-expanded="true"] {
    transform: rotateZ(90deg);
  }
}

.panel {
  background-color: unset;
  border: 0;
  box-shadow: none;
  margin-bottom: var(--panel-margin-bottom);
}

.panel-body {
  padding: 0;
}

.tab-pane {
  padding-top: 15px;
}

.panel-inner {

  // when they are multiple siblings of inner-panels
  // we add some space add the bottom their card
  // to avoid panels touching each other
  &:not(:only-child) .panel-body .card[data-is-expanded="true"] {
    padding-bottom: var(--block-spacing);
  }

  &::before {
    content: "";
    border-top: 1px solid var(--card-border-color);
    margin: 0 calc(var(--block-spacing) / 2);
    width: 100%;
  }

  .panel-title {
    font-size: var(--font-size-h4);
    font-weight: var(--card-title-font-weight);
    padding-bottom: var(--card-padding-axial);
    padding-top: var(--card-padding-axial);
  }

  .panel {
    border-bottom: 0 !important;
    margin-bottom: 0 !important;

    .panel-body {
      padding: 0 !important;
    }
  }
}