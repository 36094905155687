.bootstrap-switch {
  border: 0;
  border-radius: 30px;
  cursor: pointer;
  direction: ltr;
  display: inline-block;
  height: 26px;
  line-height: 8px;
  margin: var(--btns-margin);
  overflow: hidden;
  outline: none;
  position: relative;
  text-align: left;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  user-select: none;
  vertical-align: middle;
  width: 61px !important;
  z-index: 0;

  &.pull-right {
    margin-left: calc(2 * var(--btns-margin));
    margin-right: 0;
  }

  .bootstrap-switch-container {
    display: inline-flex;
    top: 0;
    height: 26px;
    border-radius: 4px;
    transform: translate3d(0, 0, 0);
    width: 100px !important;
  }

  .bootstrap-switch-handle-on,
  .bootstrap-switch-handle-off,
  .bootstrap-switch-label {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block !important;
    height: 100%;
    color: #fff;
    padding: 6px 12px;
    font-size: 1.1rem;
    text-indent: -5px;
    line-height: 15px;
    transition: 0.25s ease-out;
  }

  .bootstrap-switch-handle-on,
  .bootstrap-switch-handle-off {
    background-color: var(--color-black-lighter);
    float: left;
    text-align: center;
    width: 50% !important;
    z-index: 1;
  }

  .bootstrap-switch-handle-on.bootstrap-switch-primary,
  .bootstrap-switch-handle-off.bootstrap-switch-primary {
    color: #fff;
    background: var(--primary-color);
  }

  .bootstrap-switch-handle-on.bootstrap-switch-info,
  .bootstrap-switch-handle-off.bootstrap-switch-info {
    color: #fff;
    background: var(--color-blue);
  }

  .bootstrap-switch-handle-on.bootstrap-switch-success,
  .bootstrap-switch-handle-off.bootstrap-switch-success {
    color: #fff;
    background: var(--color-green);
  }

  .bootstrap-switch-handle-on.bootstrap-switch-warning,
  .bootstrap-switch-handle-off.bootstrap-switch-warning {
    background: var(--color-orange);
    color: #fff;
  }

  .bootstrap-switch-handle-on.bootstrap-switch-danger,
  .bootstrap-switch-handle-off.bootstrap-switch-danger {
    color: #fff;
    background: var(--color-red);
  }

  .bootstrap-switch-handle-on.bootstrap-switch-default,
  .bootstrap-switch-handle-off.bootstrap-switch-default {
    color: #fff;
    background: var(--color-gray);
  }

  .bootstrap-switch-label {
    background-color: #ffffff;
    box-shadow:
      0 1px 1px #ffffff inset,
      0 1px 1px rgba(0, 0, 0, 0.25);
    text-align: center;
    color: #111111;
    background: #ffffff;
    width: 22px !important;
    height: 22px;
    margin: 2px -11px;
    border-radius: 12px;
    position: relative;
    float: left;
    padding: 0;
    z-index: 10;
  }

  .bootstrap-switch-handle-on {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }

  .bootstrap-switch-handle-off {
    text-indent: 6px;
  }

  input[type="radio"],
  input[type="checkbox"] {
    position: absolute !important;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    z-index: -1;
  }

  input[type="radio"].form-control,
  input[type="checkbox"].form-control {
    height: auto;
  }

  &.bootstrap-switch-mini .bootstrap-switch-handle-on,
  &.bootstrap-switch-mini .bootstrap-switch-handle-off,
  &.bootstrap-switch-mini .bootstrap-switch-label {
    padding: 1px 5px;
    font-size: 1.2rem;
    line-height: var(--line-height);
  }

  &.bootstrap-switch-small .bootstrap-switch-handle-on,
  &.bootstrap-switch-small .bootstrap-switch-handle-off,
  &.bootstrap-switch-small .bootstrap-switch-label {
    padding: 5px 10px;
    font-size: 1.2rem;
    line-height: var(--line-height);
  }

  &.bootstrap-switch-large .bootstrap-switch-handle-on,
  &.bootstrap-switch-large .bootstrap-switch-handle-off,
  &.bootstrap-switch-large .bootstrap-switch-label {
    padding: 6px 16px;
    font-size: 1.8rem;
    line-height: 1.33;
  }

  &.bootstrap-switch-disabled,
  &.bootstrap-switch-readonly,
  &.bootstrap-switch-indeterminate {
    cursor: default !important;
  }

  &.bootstrap-switch-disabled .bootstrap-switch-handle-on,
  &.bootstrap-switch-readonly .bootstrap-switch-handle-on,
  &.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
  &.bootstrap-switch-disabled .bootstrap-switch-handle-off,
  &.bootstrap-switch-readonly .bootstrap-switch-handle-off,
  &.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
  &.bootstrap-switch-disabled .bootstrap-switch-label,
  &.bootstrap-switch-readonly .bootstrap-switch-label,
  &.bootstrap-switch-indeterminate .bootstrap-switch-label {
    opacity: 0.5;
    filter: alpha(opacity=50);
    cursor: default !important;
  }

  &.bootstrap-switch-animate .bootstrap-switch-container {
    transition: margin-left 0.5s;
  }

  &.bootstrap-switch-inverse .bootstrap-switch-handle-on {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }

  &.bootstrap-switch-inverse .bootstrap-switch-handle-off {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }

  &.bootstrap-switch-on .bootstrap-switch-container {
    margin-left: -2px !important;
  }

  &.bootstrap-switch-off .bootstrap-switch-container {
    margin-left: -37px !important;
  }

  &.bootstrap-switch-on:hover .bootstrap-switch-label {
    width: 26px !important;
    margin: 2px -15px;
  }

  &.bootstrap-switch-off:hover .bootstrap-switch-label {
    width: 26px !important;
    margin: 2px -15px -13px -11px;
  }

  &.bootstrap-switch-on .bootstrap-switch-handle-off {
    background-color: var(--color-black-lighter);
  }

  &.bootstrap-switch-off .bootstrap-switch-handle-on {
    background-color: var(--color-gray);
  }

  &.bootstrap-switch-on .bootstrap-switch-danger~.bootstrap-switch-default {
    background-color: var(--color-red);
  }

  &.bootstrap-switch-on .bootstrap-switch-warning~.bootstrap-switch-default {
    background-color: var(--color-orange);
  }

  &.bootstrap-switch-on .bootstrap-switch-success~.bootstrap-switch-default {
    background-color: var(--color-green);
  }

  &.bootstrap-switch-on .bootstrap-switch-primary~.bootstrap-switch-default {
    background-color: var(--primary-color);
  }

  &.bootstrap-switch-on .bootstrap-switch-info~.bootstrap-switch-default {
    background-color: var(--color-blue);
  }

  &.bootstrap-switch-off .bootstrap-switch-danger,
  &.bootstrap-switch-off .bootstrap-switch-primary,
  &.bootstrap-switch-off .bootstrap-switch-info,
  &.bootstrap-switch-off .bootstrap-switch-warning,
  &.bootstrap-switch-off .bootstrap-switch-success {
    background-color: var(--color-gray);
  }

  &.bootstrap-switch-focused {
    outline: var(--accessibility-outline);
    outline-offset: var(--accessibility-outline-offset);
  }
}