body,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
.btn-simple,
.alert,
a,
.td-name,
td,
button.close {
  font-family: var(--font-family);
  font-weight: normal;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-weight: 300;
  margin: 30px 0 15px;
}

h1,
.h1 {
  font-size: var(--font-size-h1);
}

h2,
.h2 {
  font-size: var(--font-size-h2);
}

h3,
.h3 {
  font-size: var(--font-size-h3);
  margin: 20px 0 10px;
}

h4,
.h4 {
  font-size: var(--font-size-h4);
}

h5,
.h5 {
  font-size: var(--font-size-h5);
  margin-bottom: 15px;
}

h6,
.h6 {
  font-size: var(--font-size-h6);
  font-weight: 600;
  text-transform: uppercase;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  color: var(--color-gray-darkest);
  font-weight: 300;
  line-height: var(--line-height);
}

h1 small,
h2 small,
h3 small,
h1 .small,
h2 .small,
h3 .small {
  font-size: 60%;
}

h1 .subtitle {
  display: block;
  margin: 0 0 30px;
}

a,
.link {
  color: var(--link-color);
  cursor: pointer;
}