html[data-rtl="true"] {
  direction: rtl;

  .pseudo-select-control {
    .search-input-icon {
      left: unset;
      right: calc(var(--input-h-padding) / 2);

      +.form-control {
        padding-left: unset;
        padding-right: calc(var(--icn-md) + var(--input-h-padding) / 2 + 2px);
      }
    }

    .pseudo-select-option[data-has-icon="true"] {
      padding: var(--input-v-padding) var(--input-h-padding);
      padding-right: calc(var(--input-h-padding) / 2);
    }
  }

  .pseudo-select-addons {
    left: 0;
    right: unset;
  }

  .select-group .select__single-value {
    padding-left: 0;
    padding-right: 5px;
  }

  th {
    text-align: right;

    i {
      margin-left: 0;
      margin-right: 2px;
    }
  }

  i {
    transform: rotateY(180deg);

    &.icn-rotate-180 {
      transform: rotateY(180deg) rotateZ(-180deg);
    }
  }

  .pages-layout {
    margin-left: 0;
    margin-right: var(--nav-controls-width);

    .global-search {
      left: 0;
      right: unset;
    }
  }

  .partner-or-tenant-logo,
  .nav-controls-subwrapper {
    left: unset;
    right: 0;
  }

  .pull-right {
    float: left !important;
  }

  .btn {
    margin-left: calc(2 * var(--btns-margin));
    margin-right: 0;

    &.pull-right {
      margin-left: 0;
      margin-right: calc(2 * var(--btns-margin));
    }
  }

  .btn-group.dropdown {
    display: inline-flex;
    margin-right: 0;

    .btn {
      &:first-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: var(--btn-border-radius);
        border-top-left-radius: 0;
        border-top-right-radius: var(--btn-border-radius);
      }

      &:nth-child(2) {
        border-bottom-left-radius: var(--btn-border-radius);
        border-bottom-right-radius: 0;
        border-top-left-radius: var(--btn-border-radius);
        border-top-right-radius: 0;
      }
    }

    .dropdown-menu {
      left: unset;
      right: 0;
    }

    &.pull-right {
      margin-left: 0;

      .dropdown-menu {
        left: 0;
        right: unset;
      }
    }
  }

  .caret {
    transform: rotate(90deg);
  }

  .section-collapse-icon[data-is-expanded="true"] {
    transform: rotateZ(-90deg) rotateY(180deg);
  }

  .nav-controls {
    padding-left: var(--scrollbar-browser-width);
    padding-right: 0;

    i {
      margin-left: var(--nav-controls-left-element-margin-right);
      margin-right: 0;
    }

    .nav-subcontrol {
      .nav-text {
        padding-left: 0;
        padding-right: 45px;

        &::after {
          left: 0;
          right: var(--nav-controls-line-v-left);
        }
      }

      &::after {
        left: 0;
        right: var(--nav-controls-line-v-left);
      }
    }
  }

  .app-controls-wrapper .app-menu {
    .app-menu-list {
      left: 0;
      right: unset;

      &::before,
      &::after {
        left: 7px;
        right: unset;
      }
    }
  }

  .modal-header .close {
    float: left;
  }

  .input-group .form-control {
    &:first-child:not(:last-child) {
      border-left: 0;
      border-right: 1px solid var(--color-gray-lighter);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: var(--border-radius-base);
      border-bottom-right-radius: var(--border-radius-base);
    }

    &:last-child:not(:first-child) {
      border-left: 1px solid var(--color-gray-lighter);
      border-right: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: var(--border-radius-base);
      border-bottom-right-radius: var(--border-radius-base);
    }

    &:focus {
      &:first-child:not(:last-child) {
        border-right-color: var(--input-border-focus-color);
      }

      &:last-child:not(:first-child) {
        border-left-color: var(--input-border-focus-color);
      }
    }
  }

  .input-group-addon {
    &:first-child {
      border-left: 0;
      border-right: 1px solid var(--color-gray-lighter);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: var(--border-radius-base);
      border-bottom-right-radius: var(--border-radius-base);

      &.form-input-addon {
        padding-left: 0;
        padding-right: var(--input-addon-h-padding);
      }
    }

    &:last-child {
      border-left: 1px solid var(--color-gray-lighter);
      border-right: 0;
      border-top-left-radius: var(--border-radius-base);
      border-bottom-left-radius: var(--border-radius-base);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &.form-input-addon {
        padding-left: var(--input-h-padding);
        padding-right: 0;
      }
    }
  }

  // we need to repeat this rule to override .input-group-addon:last-child
  .input-group:focus-within .input-group-addon {
    border-color: var(--input-border-focus-color);
  }

  .input-group[data-disabled="true"] .input-group-addon {
    padding-left: 0;
    padding-right: var(--input-addon-h-padding);
  }

  .form-input-inline {
    input {
      padding-left: 8px;
      padding-right: 4px;
    }

    .form-input-inline-controls {
      left: 4px;
      right: unset;
    }
  }

  .checkbox {
    label {
      padding-left: 0;
      padding-right: 24px;

      &::before,
      &::after {
        left: unset;
        right: -3px;
      }
    }

    input[type="checkbox"] {
      transform: rotateY(180deg);

      &:checked+label {

        &::before,
        &::after {
          transform: rotateY(180deg);
        }
      }
    }
  }

  .radio-group[data-inline="true"] {
    .radio {
      margin-left: var(--block-spacing);
      margin-right: 0;
    }
  }

  .radio {
    label {
      padding-left: unset;
      padding-right: 22px;

      &::before,
      &::after {
        left: unset;
        right: -3px;
      }
    }
  }

  .nav-tabs {
    padding-inline-start: 0;

    >li {
      float: right;

      a {
        margin-left: 2px;
        margin-right: 0;
      }
    }
  }

  // hack because the lib we use is not easily customisable with CSS
  // we need to change this lib one day
  .notifications-wrapper {
    direction: ltr;

    .notification {
      direction: rtl;
    }
  }

  .app-controls-wrapper .app-menu .user-block {
    padding: 0 8px 0 4px;

    .user-block-wrapper {
      margin-left: 4px;
      margin-right: 0;
    }
  }

  .card-control-btn {
    left: calc(var(--block-spacing) + var(--scrollbar-browser-width));
    right: unset;
    transform: rotateY(180deg);
  }

  [data-show-side-column="false"] {
    .card-control-btn {
      transform: rotateY(0);
    }
  }

  .tile-btn {
    .tile-selected-hint {
      left: 0;
      right: unset;

      i {
        margin-left: unset;
        margin-right: 12px;
      }

      &::before {
        border-left: unset;
        border-right: var(--tile-hint-size) solid transparent;
        left: 1px;
        right: unset;
      }
    }
  }

  // utilities
  [class*="mr-"] {
    margin-right: 0 !important;
  }

  .mr-5px {
    margin-left: 5px !important;
  }

  .mr-10px {
    margin-left: 10px !important;
  }

  .mr-2 {
    margin-left: 0.5rem !important;
  }

  .mr-3 {
    margin-left: 1rem !important;
  }

  .mr-4 {
    margin-left: 1.5rem !important;
  }

  .mr-5 {
    margin-left: 3rem !important;
  }

  [class*="ml-"] {
    margin-left: 0 !important;
  }

  .ml-5px {
    margin-right: 5px !important;
  }

  .ml-10px {
    margin-right: 10px !important;
  }

  .ml-2 {
    margin-right: 0.5rem !important;
  }

  .ml-3 {
    margin-right: 1rem !important;
  }

  .ml-4 {
    margin-right: 1.5rem !important;
  }

  .ml-5 {
    margin-right: 2rem !important;
  }

  .bblr-theme {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: var(--border-radius-base) !important;
  }

  .bbrr-theme {
    border-bottom-left-radius: var(--border-radius-base) !important;
    border-bottom-right-radius: 0 !important;
  }

  .btlr-theme {
    border-top-left-radius: 0 !important;
    border-top-right-radius: var(--border-radius-base) !important;
  }

  .btrr-theme {
    border-top-left-radius: var(--border-radius-base) !important;
    border-top-right-radius: 0 !important;
  }

  [class*="pdr-"] {
    padding-right: 0 !important;
  }

  [class*="pdl-"] {
    padding-left: 0 !important;
  }

  .pdr-5px {
    padding-left: 5px !important;
  }

  .pdl-5px {
    padding-right: 5px !important;
  }

  .direction-ltr {
    direction: rtl;
  }

  .direction-rtl {
    direction: ltr;
  }

  &[data-nav-controls-display-mode="top"] {
    @media (min-width: $screen-sm-1) {
      .pages-layout {
        margin-left: var(--nav-controls-width);
        margin-right: 0;
      }

      .actual-tenant-logo {
        left: unset;
        right: var(--nav-controls-width);
      }

      .app-controls-wrapper {
        left: 0;
        right: unset;
      }

      .nav-controls-subwrapper {
        left: unset;
        right: var(--nav-controls-width);

        &[data-has-partner-logo="true"] {
          .nav-controls {
            margin-left: var(--nav-controls-width);
          }

          .overflow-arrow-left {
            left: unset;
            right: var(--nav-controls-width);
          }
        }

        .nav-controls {
          .nav-text {
            padding-left: unset;
            padding-right: 0;
          }

          .nav-subcontrols {
            left: unset;
            right: 0;
          }
        }

        .overflow-arrow-left {
          left: unset;
          right: 0;

          i {
            border-right-width: 1px;
            padding-right: 4px;
          }
        }

        .overflow-arrow-right {
          left: 0;
          right: unset;

          i {
            border-left-width: 1px;
            padding-left: 4px;
          }
        }
      }
    }
  }

  &[data-input-style="border-bottom-only"] {
    .pseudo-select-control {
      .search-input-icon {
        right: 0;
      }

      .form-control {
        padding-right: calc(var(--icn-md) + 2px);
      }
    }

    .input-group .form-control:last-child {
      border-left: 0;
    }

    .input-group-addon {
      &:first-child {
        border-right: 0;

        &.form-input-addon {
          padding-right: 0;
        }

        +input.form-control {
          padding-right: var(--input-addon-h-padding);
        }
      }

      &:last-child {
        border-left: 0;
      }
    }

    .input-group[data-disabled="true"] .input-group-addon {
      padding-right: var(--input-addon-h-padding);
    }
  }

  &[data-nav-controls-link-active-display-mode="emphasis"] {
    .nav-controls {

      .nav-control,
      .nav-subcontrol {
        border-left: 0;
        border-right: 3px solid transparent;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: var(--border-radius-base);
        border-top-left-radius: var(--border-radius-base);

        &:hover,
        &.active {
          border-right-color: var(--nav-controls-link-hover-emphasis-border-color,
              var(--nav-controls-link-active-text-color, var(--nav-controls-text-color)));
        }
      }
    }
  }

  .navs-card .nav i {
    margin-left: 4px;
    margin-right: 0;
  }

  @media (max-width: $screen-sm) {
    .pages-layout {
      margin-right: 0;

      &[data-show-mobile-menu="true"] .nav-controls-subwrapper {
        transform: translateX(calc(var(--nav-controls-width)));
      }

      &[data-show-app-menu="true"] .app-controls-wrapper .app-menu .app-menu-list {
        transform: translateX(calc(var(--nav-controls-width)));
      }
    }

    .nav-controls-subwrapper {
      left: calc(-1 * var(--nav-controls-width));
      right: unset;
    }

    .app-controls-wrapper .app-menu .app-menu-list {
      left: calc(-1 * var(--nav-controls-width));
      right: unset;

      .app-menu-item-icon {
        margin: 0 0 0 var(--app-controls-dropdown-icon-margin);
      }
    }

    .app-controls-wrapper {
      left: calc(32px + var(--block-spacing));
      right: unset;
    }
  }

  @media (max-width: $screen-xs) {
    .entity-workflow-buttons {

      .transitions-top,
      .transitions-bottom {
        .btn {
          float: none !important;

          &:nth-of-type(odd) {
            margin-left: var(--btns-margin);
            margin-right: 0;
          }

          &:nth-of-type(even) {
            margin-left: 0;
            margin-right: var(--btns-margin);
          }
        }
      }
    }

    .app-controls-wrapper .app-menu .user-block {
      padding: 0;
    }

    .app-control-btn {
      margin: 0;
    }
  }

  .side-view {
    border-left: unset;
    border-right: 1px solid var(--color-gray);
    left: 0;
    right: unset;

    .side-view-gutter-wrapper {
      left: unset;
      right: 0;
    }

    .side-view-gutter {
      left: unset;
      right: calc(-1 * var(--container-padding) - 0.5px);
    }
  }
}