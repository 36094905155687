// imported and adapted from react-big-calendar/lib/css/react-big-calendar.css

.rbc-calendar button {
  border: 1px solid var(--primary-color);
  background-color: transparent;
  border-radius: var(--btn-border-radius);
  color: var(--primary-color);
  font-size: 1.2rem;
  padding: var(--btn-sm-padding);
  position: relative;

  &.rbc-active {
    background-color: var(--primary-color);
    color: var(--color-white);
  }
}

.rbc-calendar {
  align-items: stretch;
  display: flex;
  height: 100%;
  flex-direction: column;
  min-height: calc(100vh - var(--app-controls-height) - (6 * var(--block-spacing)));
}

.rbc-abs-full,
.rbc-row-bg {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: var(--muted-color);
}

.rbc-off-range-bg {
  background: var(--muted-background-color);
}

.rbc-header {
  border-bottom: 1px solid var(--color-gray-lighter);
  flex: 1 0 0%;
  font-weight: bold;
  font-size: var(--font-size-base);
  min-height: 0;
  overflow: hidden;
  padding: 0 3px;
  text-align: center;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;

  button {
    border: 0;
    color: var(--default-text-color);
    padding: 0;
  }
}

.rbc-header+.rbc-header {
  border-left: 1px solid var(--color-gray-lighter);
}

.rbc-rtl .rbc-header+.rbc-header {
  border-left-width: 0;
  border-right: 1px solid var(--color-gray-lighter);
}

.rbc-header>a,
.rbc-header>a:active,
.rbc-header>a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-content {
  position: relative;
  user-select: none;
  z-index: 4;
}

.rbc-row-content-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container {
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
  display: none;
}

.rbc-today {
  background-color: var(--info-background-color);
}

.rbc-toolbar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-base);
  justify-content: center;
  margin-bottom: 10px;
}

.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
  text-transform: capitalize;
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}

.rbc-btn-group>button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-btn-group>button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group>button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group>button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-btn-group>button:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.rbc-btn-group button+button {
  margin-left: -1px;
}

.rbc-rtl .rbc-btn-group button+button {
  margin-left: 0;
  margin-right: -1px;
}

.rbc-btn-group+.rbc-btn-group,
.rbc-btn-group+button {
  margin-left: 10px;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: var(--info-color);
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 5px;
  color: var(--color-white);
  cursor: pointer;
  margin: 0;
  padding: 2px 5px;
  text-align: left;
  width: 100%;
}

.rbc-slot-selecting .rbc-event,
.rbc-slot-selecting .rbc-day-slot .rbc-background-event,
.rbc-day-slot .rbc-slot-selecting .rbc-background-event {
  cursor: inherit;
  pointer-events: none;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: var(--info-states-color);
}

.rbc-event-label {
  font-size: 1.2rem;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-row {
  display: flex;
  flex-direction: row;
}

.rbc-row-segment {
  padding: 0 1px 1px 1px;
}

.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}

.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 1.2rem;
  height: auto;
  line-height: normal;
}

.rbc-month-view {
  border: 1px solid var(--color-gray-lighter);
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  height: 100%;
  position: relative;
  user-select: none;
  width: 100%;
}

.rbc-month-header {
  display: flex;
  flex-direction: row;
}

.rbc-month-row {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0px;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.rbc-month-row+.rbc-month-row {
  border-top: 1px solid var(--color-gray-lighter);
}

.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  text-align: right;

  button {
    border: 0;
    color: var(--default-text-color);
    padding: 0 2px 0 0;
  }
}

.rbc-date-cell.rbc-now {
  font-weight: bold;
}

.rbc-date-cell>a,
.rbc-date-cell>a:active,
.rbc-date-cell>a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden;
}

.rbc-day-bg {
  flex: 1 0 0%;
}

.rbc-day-bg+.rbc-day-bg {
  border-left: 1px solid var(--color-gray-lighter);
}

.rbc-rtl .rbc-day-bg+.rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid var(--color-gray-lighter);
}

.rbc-overlay {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-lighter);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
  position: absolute;
  z-index: 5;
}

.rbc-overlay>*+* {
  margin-top: 1px;
}

.rbc-overlay-header {
  border-bottom: 1px solid var(--color-gray-lighter);
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}

.rbc-agenda-view {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  overflow: auto;
}

.rbc-agenda-view table.rbc-agenda-table {
  border: 1px solid var(--color-gray-lighter);
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr>td {
  padding: 5px 10px;
  vertical-align: top;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td {
  border-left: 1px solid var(--color-gray-lighter);
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td {
  border-left-width: 0;
  border-right: 1px solid var(--color-gray-lighter);
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr+tr {
  border-top: 1px solid var(--color-gray-lighter);
}

.rbc-agenda-view table.rbc-agenda-table thead>tr>th {
  border-bottom: 1px solid var(--color-gray-lighter);
  padding: 3px 5px;
  text-align: left;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead>tr>th {
  text-align: right;
}

.rbc-agenda-time-cell {
  text-transform: lowercase;
}

.rbc-agenda-time-cell .rbc-continues-after:after {
  content: " »";
}

.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: "« ";
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}

.rbc-agenda-event-cell {
  width: 100%;
}

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.rbc-time-column .rbc-timeslot-group {
  flex: 1;
}

.rbc-timeslot-group {
  border-bottom: 1px solid var(--color-gray-lighter);
  display: flex;
  flex-flow: column nowrap;
  min-height: 40px;
}

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}

.rbc-label {
  padding: 0 5px;
}

.rbc-day-slot {
  position: relative;
}

.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  margin-right: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.rbc-day-slot .rbc-events-container.rbc-rtl {
  left: 10px;
  right: 0;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  align-items: flex-start;
  display: flex;
  flex-flow: column wrap;
  max-height: 100%;
  min-height: 20px;
  overflow: hidden;
  position: absolute;
}

.rbc-day-slot .rbc-background-event {
  opacity: 0.75;
}

.rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
}

.rbc-day-slot .rbc-event-content {
  flex: 1 1 0;
  height: 100%;
  line-height: 1;
  min-height: 1em;
  width: 100%;
  word-wrap: break-word;
}

.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid var(--color-gray-lightest);
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  background-color: var(--color-white);
  border-right: 1px solid var(--color-gray-lighter);
  left: 0;
  margin-right: -1px;
  position: sticky;
  z-index: 10;
}

.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}

.rbc-time-view-resources .rbc-time-header-content {
  flex: 1 0 0;
  flex-basis: 0px;
  min-width: auto;
}

.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  flex: 1 1 0;
  flex-basis: 0 px;
  width: 140px;
}

.rbc-time-header-content+.rbc-time-header-content {
  margin-left: -1px;
}

.rbc-time-slot {
  flex: 1 0 0;
}

.rbc-time-slot.rbc-now {
  font-weight: bold;
}

.rbc-day-header {
  text-align: center;
}

.rbc-slot-selection {
  background-color: var(--color-black-lightest);
  color: var(--color-white);
  font-size: 1.2rem;
  padding: 3px;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  border: 1px solid var(--color-gray-lighter);
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  width: 100%;
}

.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
}

.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  height: 100%;
  position: relative;
  width: 100%;
}

.rbc-time-view .rbc-allday-cell+.rbc-allday-cell {
  border-left: 1px solid var(--color-gray-lighter);
}

.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}

.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}

.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}

.rbc-time-header.rbc-overflowing {
  border-right: 1px solid var(--color-gray-lighter);
}

.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid var(--color-gray-lighter);
}

.rbc-time-header>.rbc-row:first-child {
  border-bottom: 1px solid var(--color-gray-lighter);
}

.rbc-time-header>.rbc-row.rbc-row-resource {
  border-bottom: 1px solid var(--color-gray-lighter);
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  border-left: 1px solid var(--color-gray-lighter);
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
}

.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid var(--color-gray-lighter);
}

.rbc-time-header-content>.rbc-row.rbc-row-resource {
  border-bottom: 1px solid var(--color-gray-lighter);
  flex-shrink: 0;
}

.rbc-time-content {
  align-items: flex-start;
  border-top: 2px solid var(--color-gray-lighter);
  display: flex;
  flex: 1 0 0%;
  overflow-y: auto;
  position: relative;
  width: 100%;
}

.rbc-time-content>.rbc-time-gutter {
  flex: none;
}

.rbc-time-content>*+*>* {
  border-left: 1px solid var(--color-gray-lighter);
}

.rbc-rtl .rbc-time-content>*+*>* {
  border-left-width: 0;
  border-right: 1px solid var(--color-gray-lighter);
}

.rbc-time-content>.rbc-day-slot {
  user-select: none;
  width: 100%;
}

.rbc-current-time-indicator {
  background-color: var(--color-states-red);
  height: 1px;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  z-index: 3;
}

@media (max-width: $screen-sm) {
  .rbc-toolbar {
    flex-direction: column;
  }

  .rbc-toolbar-label {
    margin: 8px 0;
  }
}